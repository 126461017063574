import React from "react";
import styled from "styled-components";
import Button from "../components/Button";
import { ScreenReaderOnly } from "../components/GlobalStyle";
import { media } from "../components/Breakpoints";

// Assets
import Logo from "../assets/images/logo.png";

const SplashScreen = styled.div`
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin: auto;
  color: #fff;
  height: 100vh;
  background-color: #000;
  transition: opacity 0.6s ease;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  display: ${({ $isVisible }) => ($isVisible ? "flex" : "none")};
`;

const SplashScreenLogo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const SplashScreenLogoSrc = styled.img`
  width: 100%;
  height: auto;
  max-width: 180px;

  ${media.md`
  max-width: 220px;
  `}
`;

const SplashScreenTitle = styled(ScreenReaderOnly)``;

const SplashScreenSubTitle = styled.h2`
  font-size: 22px;
  font-weight: 500;
  margin: 24px 0 8px;
  padding: 0;
  color: #fff;
`;

const SplashScreenContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 8px;
  margin: 8px 0 32px;

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #fff;
  }
`;

const SplashScreenButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  ${media.md`
      flex-direction: row;
    `}
`;

const SplashScreenComponent = ({
  isVisible,
  visible,
  fadeOut,
  handleTransition,
  formatDate,
  clearLocalStorage,
  ModalContentTypes,
  loadGameFromHistory,
  setCurrentGameId,
  setNewGamesAvailable, // Receive the function here
  newGamesAvailable, // Optionally use this to conditionally show/hide elements
  NewGamesAvailableBanner,
}) => {
  // Calculate total games count directly from gameHistory
  const handlePlayClick = () => {
    const totalGamesCount = parseInt(
      localStorage.getItem("totalGamesCount"),
      10
    );
    const gameHistoryRaw = localStorage.getItem("gameHistory");
    const gameHistory = gameHistoryRaw ? JSON.parse(gameHistoryRaw) : {};
    const highestGameIdPlayed = Math.max(
      ...Object.keys(gameHistory).map(Number),
      0
    );

    let nextGameId = highestGameIdPlayed + 1;
    if (nextGameId <= totalGamesCount) {
      setCurrentGameId(nextGameId);
      loadGameFromHistory(nextGameId);
    }

    setNewGamesAvailable(false);
    handleTransition();
  };

  return (
    <SplashScreen $isVisible={!fadeOut}>
      <SplashScreenLogo>
        <SplashScreenLogoSrc src={Logo} alt="Logo" />
      </SplashScreenLogo>
      <SplashScreenTitle>StripTacToe.com</SplashScreenTitle>
      <SplashScreenSubTitle>Stripper? I barely knew her!</SplashScreenSubTitle>
      <SplashScreenContent>
        <p>{formatDate()}</p>
      </SplashScreenContent>
      <NewGamesAvailableBanner visible={newGamesAvailable} />
      <SplashScreenButtonContainer>
        <Button
          size="lg"
          type="outline"
          text="How to Play"
          $minWidth={true}
          onClick={() => {
            setNewGamesAvailable(false); // Also hide the banner when How to Play is clicked
            handleTransition(ModalContentTypes.HELP);
          }}
        />
        <Button
          size="lg"
          type="primary"
          text="Play"
          $minWidth={true}
          onClick={handlePlayClick}
        />
      </SplashScreenButtonContainer>
    </SplashScreen>
  );
};

export default SplashScreenComponent;
