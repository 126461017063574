import React from "react";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import styled from "styled-components";
import Button from "./Button";
import { ReactComponent as IconCircleXMark } from "../assets/svg/IconCircleXMark.svg";

import GameStatsComponent from "./GameStatsComponent";
import GameHelp from "./GameHelp";
import ShareComponent from "./ShareComponent";
// import EmojiGrid from "./EmojiGrid";
import ChatApp from "./ChatApp";
import * as constants from "../constants";
import { media } from "../components/Breakpoints";

// Styled components
const ModalHeader = styled.div`
  display: flex;
  padding: 0 24px 0;
  margin-top: 24px;
  margin-bottom: 16px;
  align-items: flex-start;

  ${media.md`
  padding: 0 32px 0;
  `}
`;

const ModalTitle = styled.h2`
  text-align: left;
  margin: 0;
  font-size: 20px;
  width: 100%;

  ${media.md`
  font-size: 22px;
  `}
`;

const ModalClose = styled(Button)`
  margin: 0;
  padding-right: 0;
  padding-top: 0;

  svg {
    fill: #fff;
  }
`;
const ModalContent = styled.div`
  padding: 8px 24px 24px;

  ${media.md`
  padding: 8px 32px 32px;
  `}

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ModalContentWP = styled.div`
  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  li {
    margin-bottom: 20px;
  }

  address {
    font-style: normal;
  }

  .list-reset {
    padding: 0;
    margin: 0 0 24px;
    list-style-type: none;

    li {
      margin: 0 0 4px;
    }
  }

  ol {
    ol {
      list-style-type: upper-alpha;
    }
  }
`;

const ModalComponent = ({
  isOpen,
  onClose,
  currentModalContent,
  currentGameId,
  gameCount,
  userWins,
  userLosses,
  userDraws,
  board,
  version,
  winner,
  userName,
  firstMediaThumbnail,
  lastMediaThumbnail,
  currentGame,
  setIsLoading,
  clearLocalStorage,
}) => {
  return (
    <Modal
      styles={{
        modal: {
          borderRadius: "8px",
          padding: "0",
          margin: "24px 0",
          maxWidth: "500px",
          width: "calc(100% - 48px)",
          backgroundColor: "#333",
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.9)",
        },
      }}
      blockScroll={true}
      open={isOpen}
      onClose={onClose}
      center
      showCloseIcon={false}
      initialFocusRef={false}
    >
      <ModalHeader>
        <ModalTitle>
          {currentModalContent
            ? currentModalContent.title.split("\n").map((item, key) => (
                <React.Fragment key={key}>
                  {item}
                  {key < currentModalContent.title.split("\n").length - 1 && (
                    <br />
                  )}
                </React.Fragment>
              ))
            : "Default Title"}
        </ModalTitle>

        <ModalClose
          size="md"
          icon={IconCircleXMark}
          ariaLabel="Close"
          onClick={onClose}
        />
      </ModalHeader>

      <ModalContent>
        {currentModalContent === constants.ModalContentTypes.SHARE && (
          <>
            <ShareComponent
              currentGameId={currentGameId}
              board={board}
              userName={userName}
              version={version}
              getEmoji={(cell, currentVersion) =>
                constants.MODES_CONFIG[currentVersion].getEmoji(cell, true)
              }
            />
          </>
        )}
        {currentModalContent === constants.ModalContentTypes.STATS && (
          <GameStatsComponent
            currentGameId={currentGameId}
            gameCount={gameCount}
            userWins={userWins}
            userLosses={userLosses}
            userDraws={userDraws}
          />
        )}
        {currentModalContent === constants.ModalContentTypes.HELP && (
          <ModalContentWP>
            <GameHelp clearLocalStorage={clearLocalStorage} />
          </ModalContentWP>
        )}
        {currentModalContent === constants.ModalContentTypes.END && (
          <>
            <p>
              Hit up <strong>{userName}</strong> for more sexy pics.
            </p>
            <ChatApp
              userImage={firstMediaThumbnail}
              userName={userName}
              lastImage={lastMediaThumbnail}
              chatLink={
                currentGame.website?.link + currentGame.website?.affiliate
              }
              showCloseButton={false}
              onClose={() => setIsLoading(false)}
              theme="complete"
              chatId={currentGame.id}
            />
          </>
        )}
        {/* Default case for page content */}
        {currentModalContent.content && (
          <ModalContentWP
            dangerouslySetInnerHTML={{ __html: currentModalContent.content }}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModalComponent;
