import React from "react";
import styled from "styled-components";

// Styled component definition
const GameIdTag = styled.span`
  display: inline-flex;
  color: #fff;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 4px;
  padding: 4px 0;
  gap: 8px;
  border: 1px solid #222;
`;

const GameIdTagDifficulty = styled.span``;
const GameIdTagText = styled.span``;

const GameIdTagComponent = ({ text, getDifficultyEmoji, difficulty }) => {
  return (
    <GameIdTag>
      <GameIdTagDifficulty>
        {getDifficultyEmoji(difficulty)}
      </GameIdTagDifficulty>
      <GameIdTagText>No. {text}</GameIdTagText>
    </GameIdTag>
  );
};

export default GameIdTagComponent;
