import { API_KEY, BASE_URL } from "./constants.js";

// Function to fetch ads from WordPress and their media details
export async function fetchAds() {
  try {
    const response = await fetch(`${BASE_URL}/ads`, {
      headers: {
        "X-API-Key": API_KEY,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const adsData = await response.json();
    // Fetch media URLs for each ad
    const adsWithMediaDetails = await Promise.all(
      adsData.map(async (ad) => {
        const mediaId = ad.acf.media;
        const mediaDetails = await fetchMediaUrlById(mediaId);
        return {
          id: ad.id,
          type: ad.acf.type,
          name: ad.acf.name ? ad.acf.name : ad.title.rendered,
          url: ad.acf.url,
          media: mediaDetails.imageUrl, // Replace with the actual image URL from media details
          thumbnail: mediaDetails.thumbnail, // Assuming you want to use a specific thumbnail size
        };
      })
    );

    return adsWithMediaDetails;
  } catch (error) {
    console.error("Error fetching ads:", error);
    return [];
  }
}

// Fetch media details by ID, including custom fields for videos
async function fetchMediaUrlById(mediaId) {
  if (!mediaId || isNaN(parseInt(mediaId))) {
    console.error(`Invalid media ID: ${mediaId}`);
    return null;
  }

  try {
    const response = await fetch(`${BASE_URL}/media/${mediaId}`, {
      headers: {
        "X-API-Key": API_KEY,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(
        `Failed to fetch media with ID ${mediaId}: ${response.statusText}`
      );
    }
    const media = await response.json();

    let thumbnailUrl = media.source_url; // Default thumbnail URL

    // Use these functions in your comparison logic
    const uploadedFileName = decodeURIComponent(
      media.source_url.split("/").pop().split("?")[0]
    )
      .replace(/\.[^/.]+$/, "")
      .toLowerCase();
    const normalizedFileName = normalizeStringForComparison(uploadedFileName);
    const normalizedTitle = normalizeStringForComparison(media.title.rendered);

    // Remove numerical suffixes for a fair comparison
    const fileNameForComparison = removeNumericalSuffix(normalizedFileName);
    const titleForComparison = removeNumericalSuffix(normalizedTitle);

    // Decide to use the title based on comparison
    let title =
      fileNameForComparison === titleForComparison ? "" : media.title.rendered;

    // If the media is a video, attempt to extract the poster image from the description
    if (media.mime_type.startsWith("video")) {
      const posterMatch = media.description.rendered.match(/poster="([^"]+)"/);
      if (posterMatch && posterMatch[1]) {
        thumbnailUrl = posterMatch[1]; // Use the extracted poster URL as the thumbnail
      }
    }

    // Define thumbnail sizes, utilizing the potentially updated thumbnailUrl for videos
    const thumbnailSizes = {
      gameMainThumbnail:
        media.media_details.sizes["game-main-thumbnail"]?.source_url ||
        thumbnailUrl,
      gameNextThumbnail:
        media.media_details.sizes["game-next-thumbnail"]?.source_url ||
        thumbnailUrl,
      gameArchiveThumbnail:
        media.media_details.sizes["game-archive-thumbnail"]?.source_url ||
        thumbnailUrl,
    };

    const cleanDescription = media.description.rendered
      .replace(/<\/?[^>]+(>|$)/g, "") // Remove HTML tags
      .replace(/document\.createElement\('video'\);?/g, "") // Remove specific JS code
      .replace(/https?:\/\/[^\s]+/g, "") // Remove URLs
      .trim();

    // Return structured media data, with handling for featured image URLs for videos
    return {
      ...thumbnailSizes,
      imageUrl: media.source_url, // Original media source URL
      id: mediaId,
      type: media.mime_type.startsWith("video") ? "video" : "image",
      thumbnail: thumbnailUrl, // Updated to use featured image URL if video
      title,
      description: cleanDescription,
    };
  } catch (error) {
    console.error(`Error fetching media URL for ID ${mediaId}:`, error);
    return null;
  }
}

// Fetch URLs and details for a batch of media IDs
async function fetchMediaUrlsByIds(mediaIds) {
  const validMediaIds = mediaIds.filter((id) => !isNaN(parseInt(id)));
  const promises = validMediaIds.map((id) => fetchMediaUrlById(id));
  const mediaDetailsArray = await Promise.all(promises);

  return mediaDetailsArray.reduce((acc, details) => {
    if (details) acc[details.id] = details;
    return acc;
  }, {});
}

export async function fetchTotalGames() {
  let allGames = [];
  let page = 1;
  let totalGames = 0;

  try {
    while (true) {
      const response = await fetch(
        `${BASE_URL}/models?status=publish&per_page=100&page=${page}`,
        {
          headers: {
            "X-API-Key": API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();
      allGames = allGames.concat(data);

      if (data.length < 100) {
        // No more pages left
        break;
      }

      page++;
    }

    totalGames = allGames.length;
    localStorage.setItem("highestAvailableGameId", totalGames.toString());
    return totalGames;
  } catch (error) {
    console.error("Error fetching total games:", error);
    return 0;
  }
}

export async function fetchModels(sequentialId = 1) {
  const cacheKey = `game-${sequentialId}`;
  const cachedGameData = localStorage.getItem(cacheKey);

  // Check if we have cached data
  if (cachedGameData) {
    // console.log(`Using cached data for game ID ${sequentialId}`);
    return JSON.parse(cachedGameData);
  }

  // console.log(
  //   `Fetching models from the server for sequential ID ${sequentialId}...`
  // );

  let allGames = [];
  let page = 1;

  try {
    while (true) {
      const response = await fetch(
        `${BASE_URL}/models?status=publish&per_page=100&page=${page}`,
        {
          headers: {
            "X-API-Key": API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const data = await response.json();
      allGames = allGames.concat(data);

      if (data.length < 100) {
        // No more pages left
        break;
      }

      page++;
    }

    // console.log(`Received ${allGames.length} games from the server.`);

    // Sort the data by original ID to ensure correct order
    allGames.sort((a, b) => a.id - b.id);

    // Log all models with their indices
    // console.log(
    //   "All fetched models with their indices:",
    //   allGames.map((item, index) => ({
    //     index: index + 1,
    //     id: item.id,
    //   }))
    // );

    // Finding the model corresponding to the requested sequentialId
    const model = allGames.find((item, index) => index + 1 === sequentialId);
    if (!model) {
      throw new Error(
        `Game not found for the requested sequential ID ${sequentialId}`
      );
    }

    const mediaUrls = await fetchMediaUrlsByIds(model.acf.gallery || []);
    const media = (model.acf.gallery || []).map((galleryItemId) => {
      const mediaDetails = mediaUrls[galleryItemId];
      return {
        ...mediaDetails,
        title: mediaDetails.title,
        description: mediaDetails.description,
        creditName: mediaDetails.creditName,
        creditUrl: mediaDetails.creditUrl,
      };
    });

    const gameData = {
      id: sequentialId,
      originalId: model.id,
      difficulty: model.acf.difficulty,
      name: model.title.rendered,
      bio: model.acf.bio,
      age: model.acf.age,
      website: {
        link: model.acf.website_link,
        anchor: model.acf.website_anchor,
        affiliate: model.acf.website_affiliate,
      },
      media,
    };

    // Cache the fetched game data
    localStorage.setItem(cacheKey, JSON.stringify(gameData));

    return gameData;
  } catch (error) {
    console.error(`Error fetching game data for ID ${sequentialId}:`, error);
    return null; // Fallback to prevent application crash
  }
}

function normalizeStringForComparison(str) {
  // Lowercase, replace underscores and hyphens with spaces for uniform comparison
  return str
    .toLowerCase()
    .replace(/[\W_]+/g, " ")
    .trim();
}

function removeNumericalSuffix(str) {
  // Remove a trailing numerical suffix possibly added by WordPress
  return str.replace(/\s\d+$/, "");
}
