import React, { useEffect, useState, useMemo } from "react";
import { media } from "../components/Breakpoints";

import fetchPageContentById from "../wordpressServiceContent";
import styled from "styled-components";
import { FOOTER_MENU } from "../constants";

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 24px 0;
`;

const FooterList = styled.ul`
  display: inline-flex;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0 0 16px;

  ${media.sm`
  gap: 16px;
  flex-direction: row;
  margin: 0 0 4px;
  `}
`;

const FooterItem = styled.li`
  margin: 0;
  padding: 0;
`;

const FooterLink = styled.div`
  cursor: pointer;
  color: #777;
  font-size: 14px;

  &:focus,
  &:hover {
    color: #7ce3cb;
  }
`;

const FooterCopyright = styled.p`
  color: #777;
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

const Footer = ({ openModalWithContent }) => {
  const [pageContent, setPageContent] = useState({});

  // Memoize to ensure pageIds doesn't change
  const pageIds = useMemo(() => FOOTER_MENU, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchPageContentById(pageIds); // Fetch pages by their IDs
      setPageContent(data); // Store fetched content in state
    };

    fetchData(); // Fetch data on component mount
  }, [pageIds]);

  const handleFooterLinkClick = (pageId) => {
    const content = pageContent[pageId] || { title: "Unknown", content: "" };

    openModalWithContent({
      type: `page-${pageId}`, // Type based on page ID
      title: content.title,
      content: content.content,
    });
  };

  // Get sorted keys based on pageIds to maintain the correct order
  const sortedPageKeys = pageIds.filter(
    (id) => pageContent[id] && pageContent[id].title
  );

  return (
    <FooterContainer>
      <FooterList>
        {sortedPageKeys.map((pageId) => (
          <FooterItem key={pageId}>
            <FooterLink onClick={() => handleFooterLinkClick(pageId)}>
              {pageContent[pageId].title}
            </FooterLink>
          </FooterItem>
        ))}
      </FooterList>
      <FooterCopyright>
        &copy; {new Date().getFullYear()} StripTacToe.com. All rights reserved.
      </FooterCopyright>
    </FooterContainer>
  );
};

export default Footer;
