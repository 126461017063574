import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import qs from "qs";

import styled, { css } from "styled-components";
import * as constants from "./constants";

import { useImageLoading } from "./ImageLoadingContext";
import { fetchModels, fetchAds, fetchTotalGames } from "./wordpressService";

import SplashScreenComponent from "./components/SplashScreen";
import Button from "./components/Button";
import ArchiveComponent from "./components/Archive";
import LoadingSpinner from "./components/Spinner";
import GameIdTagComponent from "./components/GameIdTag";
import SidebarComponent from "./components/SidebarComponent";
import ModalComponent from "./components/ModalComponent";
import MediaDisplayComponent from "./components/MediaDisplayComponent";
import TooltipComponent from "./components/TooltipComponent";
import Footer from "./components/Footer";

import useBreakpoint, { media } from "./components/Breakpoints";

import CardComponent from "./components/Card";
import GameInfoComponent from "./components/GameInfoComponent";
import ChatApp from "./components/ChatApp";

// Assets
import Logo from "./assets/images/logo-type.png";

// Icons
import { ReactComponent as IconForward } from "./assets/svg/IconForward.svg";
import { ReactComponent as IconBackward } from "./assets/svg/IconBackward.svg";
import { ReactComponent as IconRefresh } from "./assets/svg/IconRefresh.svg";
import { ReactComponent as IconCircleQuestion } from "./assets/svg/CircleQuestion.svg";
import { ReactComponent as IconChartSimple } from "./assets/svg/ChartSimple.svg";

const AppWrapper = styled.div`
  background-color: #000000;
`;

const ThumbnailComponent = styled.div`
  position: relative;
  display: flex;
  gap: 16px;

  ${media.md`
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(3, minmax(56px, 1fr)); 
  `}
`;

const ThumbnailContainer = styled.div`
  position: relative;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 12px 12px -12px rgba(0, 0, 0, 0.1);
`;

const Thumbnail = styled.img`
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
  opacity: ${({ $shouldDisplayClear }) => ($shouldDisplayClear ? 1 : 0.5)};
  filter: ${({ $shouldDisplayClear }) =>
    $shouldDisplayClear ? "none" : "blur(2px)"};
  transition: all 0.3s ease;
`;

const ModelDetails = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.md`
    gap: 12px;
  `}
`;

const GameBlockerSpecial = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  position: relative;
  width: calc(100% - 48px);
`;

const GameBlockerSpecialEndGameHeader = styled.div`
  margin: 0;
  padding: 0;
  text-align: center;

  ${media.sm`
  margin: 0 0 24px;
`}

  p {
    margin: 0;
    padding: 0;
  }
`;

const GameBlockerSpecialEndGame = styled.h2`
  font-size: 16px;
  margin: 0;
  padding: 0;

  ${media.sm`
  font-size: 22px;
  margin: 0 0 4px;
`}
`;

const GameBlocker = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #222;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const GameBlockerButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const GameScreen = styled.div`
  flex-direction: column;
  transition: opacity 1s ease;
  padding: 0 24px;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  display: ${({ $isVisible }) => ($isVisible ? "flex" : "none")};
`;

const GameScreenLogo = styled.img`
  max-width: 140px;
  height: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  background-color: transparent;
`;

const HeaderActions = styled.div`
  display: flex;
  gap: 8px;
`;
const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: opacity ${constants.TRANSITION_DURATION}ms ease-in-out;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
`;

const MainContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  ${media.md`
    flex-direction: row;
    `}
`;

const GameBoardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  overflow: hidden;
  background-color: #222;
  box-shadow: 0 12px 12px -12px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const GameBoard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #222;
  align-items: center;
  padding: 32px 16px;
  width: 100%;
  margin: auto;

  ${media.sm`
  padding: 24px;
  position: relative;
  `}

  ${media.md`
      gap: 4px;
      min-width: 240px; 
      width: 100%;
      padding: 40px;
    `}
`;

const GameBoardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 52px);
  grid-template-rows: repeat(3, 52px);
  gap: 8px;
  justify-content: center;
  position: relative;
  padding: 24px 0;

  ${media.xs`
  grid-template-columns: repeat(3, 64px);
  grid-template-rows: repeat(3, 64px);
  padding: 32px 0;
  `}

  ${media.sm`
  grid-template-columns: repeat(3, 80px);
  grid-template-rows: repeat(3, 80px);
  gap: 12px;
  `}

  ${media.md`
    grid-template-columns: repeat(3, 92px);
    grid-template-rows: repeat(3, 92px);
    `}
`;

const Square = styled.button`
  background-color: transparent;
  border: none;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  ${media.sm`
  font-size: 22px;
  `}

  ${media.md`
  font-size: 26px;
  `}

  @keyframes jump {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  @keyframes squish {
    0% {
      transform: scale(1);
      background-color: #acc6d9;
    }
    50% {
      transform: scale(1.06);
    }
    100% {
      transform: scale(1);
      background-color: #d8efff;
    }
  }

  &:before {
    transition: transform 0.2s ease-in-out;
    content: "";
    background-color: #d8efff;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 8px;
  }

  /* Apply squish animation on click */
  ${({ $isClicked, $isGameActive, $isActive }) =>
    $isClicked &&
    $isGameActive &&
    !$isActive &&
    css`
      &:hover:before {
        animation: squish 0.4s ease forwards;
      }
    `}

  /* Scale for empty squares when game is active */
  ${({ $hoverIcon, $isGameActive }) =>
    $hoverIcon &&
    $isGameActive &&
    css`
      &:hover:before {
        background-color: #acc6d9;
        transform: scale(0.96);
      }
    `}

  /* Ensure square remains static (scale 1.0) when it has an icon or game is concluded */
  ${({ $isGameActive }) =>
    !$isGameActive &&
    css`
      &:hover:before {
        transform: scale(1);
      }
    `}

  ${({ $isActive, $isWinner, $animationDelay }) =>
    $isActive &&
    css`
      &:before {
        background-color: ${$isWinner === "X"
          ? constants.COLOR_WIN
          : $isWinner === "O"
          ? constants.COLOR_LOSS
          : constants.COLOR_DRAW};
        animation: jump 0.4s ease-in ${$animationDelay + 0.2}s;
      }
    `}

    ${({ $isActive, $hoverIcon, $isGameActive }) =>
    !$isActive &&
    $isGameActive &&
    $hoverIcon &&
    css`
      &:hover:after {
        content: "${$hoverIcon}";
        opacity: 0.5;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        z-index: 1;

        ${media.sm`
        font-size: 22px;
        `}

        ${media.md`
        font-size: 26px;
        `}
      }
    `}
`;
const LayoutContainer = styled.div`
  max-width: 1094px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
  width: 100%;
`;

const ActionText = styled.div``;

const ProfileContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  width: 48px;
  height: 48px;
  min-width: 48px;
  border-radius: 50%;
  z-index: 1;
  user-select: none;
`;

const ProfileEmoji = styled.span`
  font-size: 20px;
  line-height: 1;
`;

const NextGameButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const Overlay = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;

  padding: ${({ result }) =>
    result === "playing" || result === "thinking" ? "4px 0" : "4px 16px"};
  color: ${({ result }) =>
    result === "playing" || result === "thinking" ? "#FFF" : "#000"};
  background-color: ${({ result }) =>
    result === "win"
      ? constants.COLOR_WIN
      : result === "loss"
      ? constants.COLOR_LOSS
      : result === "draw"
      ? constants.COLOR_DRAW
      : "transparent"};

  ${media.xs`
      font-size: 20px;
      font-weight: normal;
      padding: 6px 16px;
      `}
  ${media.sm`
    font-size: 26px;
    `}
`;

const NewGamesBanner = styled.div`
  position: fixed;
  top: 16px;
  right: 16px;
  background-color: #7ce3cb;
  color: #000;
  padding: 10px;
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const NewGamesAvailableBanner = ({ visible }) => {
  if (!visible) return null;
  return <NewGamesBanner>New games available!</NewGamesBanner>;
};

// Track hit requests
export const trackVisitor = () => {
  const pageUri = btoa(window.location.pathname);
  const referrer = document.referrer || "";

  const data = qs.stringify({
    wp_statistics_hit: 1,
    source_type: "home",
    source_id: 0,
    search_query: "",
    page_uri: pageUri,
    referred: referrer,
  });

  axios.post(
    "https://striptactoe.com/wordpress/wp-json/wp-statistics/v2/hit",
    data,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};

// Daily Streak
const updateDailyStreak = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const initDate = localStorage.getItem("initDate");
  const lastVisit = initDate ? new Date(initDate) : null;

  const streak = localStorage.getItem("streak")
    ? parseInt(localStorage.getItem("streak"), 10)
    : 0;

  if (lastVisit) {
    const differenceInDays = (today - lastVisit) / (1000 * 3600 * 24);

    if (differenceInDays < 1) {
      // User already visited today, do nothing
      return;
    } else if (differenceInDays === 1) {
      // User visited consecutively, increment streak
      localStorage.setItem("streak", streak + 1);
    } else {
      // User missed at least a day, reset streak
      localStorage.setItem("streak", 1);
    }
  } else {
    // First visit or data not available, start streak
    localStorage.setItem("streak", 1);
  }

  localStorage.setItem("initDate", today.toISOString());
};

const initialBoard = Array(9).fill(null);

const App = () => {
  const { isDesktop } = useBreakpoint();
  const [isVisible, setIsVisible] = useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [board, setBoard] = useState(initialBoard);
  const [currentGameId, setCurrentGameId] = useState(
    parseInt(localStorage.getItem(`lastGamePlayed`)) || 1
  );
  const [lastGamePlayed, setLastGamePlayed] = useState(
    parseInt(localStorage.getItem(`lastGamePlayed`)) || 1
  );

  const [isXNext, setIsXNext] = useState(true);

  const [highestAvailableGameId, setHighestAvailableGameId] = useState(
    parseInt(localStorage.getItem(`highestAvailableGameId`)) || 0
  );
  const [newGamesAvailable, setNewGamesAvailable] = useState(false);

  function useStoredNumber(key, initialValue = 0) {
    const [value, setValue] = useState(() => {
      const storedValue = parseInt(localStorage.getItem(key), 10);
      return isNaN(storedValue) ? initialValue : storedValue;
    });

    useEffect(() => {
      const handler = () => {
        const storedValue = parseInt(localStorage.getItem(key), 10);
        if (!isNaN(storedValue)) {
          setValue(storedValue);
        }
      };
      window.addEventListener("storage", handler);
      return () => window.removeEventListener("storage", handler);
    }, [key]);

    return value;
  }

  // Track hits to WP Statistics
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      trackVisitor();
    }
  }, []);

  // Call this function when the app component mounts
  const [gameHistory, setGameHistory] = useState(
    JSON.parse(localStorage.getItem(`gameHistory`)) || {}
  );

  const [gameCount, setGameCount] = useState(
    parseInt(localStorage.getItem(`gameCount`)) || 0
  );
  const [winner, setWinner] = useState(null);
  const [userWins, setUserWins] = useState(
    parseInt(localStorage.getItem(`userWins`)) || 0
  );
  const [userLosses, setUserLosses] = useState(
    parseInt(localStorage.getItem(`userLosses`)) || 0
  );
  const [userDraws, setUserDraws] = useState(
    parseInt(localStorage.getItem(`userDraws`)) || 0
  );
  const [currentMove, setCurrentMove] = useState(0);
  const [winningSquares, setWinningSquares] = useState([]);

  const [isNewGame, setIsNewGame] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [userIcon, setUserIcon] = useState("");
  const [mainImageLoading, setMainImageLoading] = useState(true);
  const [gameImagesData, setGameImagesData] = useState([1]);
  const [savedGamesImageData, setSavedGamesImageData] = useState(
    JSON.parse(localStorage.getItem("savedGamesImageData")) || []
  );

  const [difficulty, setDifficulty] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [specialData, setSpecialData] = useState([]);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [showGameScreen, setShowGameScreen] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [version, setVersion] = useState(
    localStorage.getItem("version") || "wild"
  );
  const [currentPage, setCurrentPage] = useState(1);
  const { isImageLoaded, markImageAsLoaded } = useImageLoading();
  const [currentModalContent, setCurrentModalContent] = useState(
    constants.ModalContentTypes.NONE
  );

  const [gameConcluded, setGameConcluded] = useState(false);

  const [statusMessage, setStatusMessage] = useState({
    message: "",
    result: "playing",
  });

  const [totalGamesCount] = useState(0);

  const GameBlockerContent = ({ currentGameId, gameImagesData }) => {
    const highestAvailableGameId = useStoredNumber(`highestAvailableGameId`);
    const isLastGame = currentGameId === highestAvailableGameId;

    return (
      <GameBlocker>
        <GameBlockerSpecial>
          {isLastGame && (
            <GameBlockerSpecialEndGameHeader>
              <GameBlockerSpecialEndGame>Game Over</GameBlockerSpecialEndGame>
              <p>Refresh daily for more levels.</p>
            </GameBlockerSpecialEndGameHeader>
          )}
          <ChatApp
            userImage={firstMediaThumbnail}
            userName={userName}
            lastImage={lastMediaThumbnail}
            chatLink={
              currentGame?.website?.link + currentGame?.website?.affiliate
            }
            chatAffiliate={currentGame?.website?.affiliate}
            showCloseButton={showCloseButton}
            onClose={() => setIsLoading(false)}
            theme={
              isLastGame
                ? isDesktop
                  ? "complete"
                  : "pending"
                : isDesktop
                ? "complete"
                : "pending"
            }
            chatId={currentGame.id}
          />
        </GameBlockerSpecial>

        {!isLastGame && (
          <GameBlockerButtons>
            <Button
              size={isDesktop ? "lg" : "md"}
              type="outline"
              text="Share"
              onClick={() =>
                openModalWithContent(constants.ModalContentTypes.SHARE)
              }
            />

            <Button
              size={isDesktop ? "lg" : "md"}
              type="outline"
              text="Stats"
              onClick={() =>
                openModalWithContent(constants.ModalContentTypes.STATS)
              }
            />

            <Button
              size={isDesktop ? "lg" : "md"}
              type="secondary"
              icon={IconForward}
              iconPosition="right"
              text={
                isLastGame
                  ? "Check Back Soon"
                  : `Next${
                      nextButtonCountdown > 0 ? ` (${nextButtonCountdown})` : ""
                    }`
              }
              onClick={handleNextGame}
              disabled={
                isLastGame ||
                nextButtonCountdown > 0 ||
                (!gameConcluded && !winner && !isBoardFull(board))
              }
            />
          </GameBlockerButtons>
        )}
      </GameBlocker>
    );
  };

  // Countdown
  const [nextButtonCountdown, setNextButtonCountdown] = useState(0);
  const [showCloseButton, setShowCloseButton] = useState(false);
  useEffect(() => {
    if (nextButtonCountdown <= 0) {
      setShowCloseButton(true);
    } else {
      setShowCloseButton(false);
    }
  }, [nextButtonCountdown]);

  const handleTransition = (modalContentType) => {
    setFadeOut(true);
    setTimeout(() => {
      setShowSplashScreen(false);
      setShowGameScreen(true);

      if (modalContentType) {
        openModalWithContent(modalContentType);
      }
    }, 0); // Adjust this delay to be shorter if necessary
  };

  // Handler to cycle through the modes
  const cycleMode = () => {
    const modeKeys = Object.keys(constants.MODES_CONFIG);
    const currentModeIndex = modeKeys.indexOf(version);
    const nextIndex = (currentModeIndex + 1) % modeKeys.length;
    const nextVersion = modeKeys[nextIndex];
    handleVersionChange(nextVersion);
  };

  // Function to open the modal with the specified content
  const openModalWithContent = (contentType) => {
    setCurrentModalContent(contentType);
    setIsOpen(true);
  };

  const difficultyEmojiMap = {
    easy: "🟢",
    medium: "🟡",
    hard: "🔴",
  };

  useEffect(() => {
    const loadAds = async () => {
      const ads = await fetchAds();
      setSpecialData(ads);
    };

    loadAds();
  }, []);

  useEffect(() => {
    const fetchGameCountAndUpdateState = async () => {
      const fetchedGameCount = await fetchTotalGames(); // fetch the total games from your service

      if (fetchedGameCount > highestAvailableGameId) {
        localStorage.setItem(
          "highestAvailableGameId",
          fetchedGameCount.toString()
        );
        setHighestAvailableGameId(fetchedGameCount);
        setNewGamesAvailable(true);
      }
    };

    fetchGameCountAndUpdateState();
    // eslint-disable-next-line
  }, []);

  // Initialize app and fetch initial data
  useEffect(() => {
    const initializeGame = async () => {
      setDataLoading(true);

      try {
        // Fetch and set total games count
        const totalGames = await fetchTotalGames();
        setHighestAvailableGameId(totalGames);

        // Fetch game data based on the last game played or start from the first game
        const gameIdToFetch = currentGameId;

        const gameData = await fetchModels(gameIdToFetch);

        if (gameData) {
          setGameImagesData([gameData]);
        } else {
          console.error(
            `initializeGame No data returned for game ID ${gameIdToFetch}`
          );
        }
      } catch (error) {
        console.error("initializeGame Error fetching data:", error);
      }

      setDataLoading(false);
    };

    initializeGame();
  }, [currentGameId]); // Dependency on currentGameId ensures the correct game is loaded

  useEffect(() => {
    updateDailyStreak();

    const highestGameIdInData = Math.max(
      ...gameImagesData.map((game) => game.id)
    );

    // Set the highest game ID reached on first visit or after clearing local storage
    if (!localStorage.getItem("highestAvailableGameId")) {
      localStorage.setItem(
        "highestAvailableGameId",
        highestGameIdInData.toString()
      );
    }
  }, [gameImagesData]);

  const clearLocalStorage = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleMainImageLoad = () => {
    setMainImageLoading(false);
  };

  const renderThumbnails = () => {
    return (
      <ThumbnailComponent>
        {dataLoading || !currentGame.media
          ? // If data is still loading, show placeholders for expected thumbnails
            Array.from({
              length: currentGame.media
                ? Object.keys(currentGame.media).length
                : 6,
            }).map((_, index) => (
              <ThumbnailContainer key={`placeholder-${index}`}>
                <LoadingSpinner isAbsolute={true} />
              </ThumbnailContainer>
            ))
          : // If data is loaded, render actual thumbnails
            Object.entries(currentGame.media || {}).map(([key, mediaItem]) => {
              const thumbnailUrl =
                mediaItem.gameNextThumbnail ||
                (mediaItem.type === "image" ? mediaItem.source : "");
              const isItLoading = !isImageLoaded(thumbnailUrl);

              const hasContent =
                mediaItem.title ||
                mediaItem.description ||
                mediaItem.creditName;

              return hasContent ? (
                <ThumbnailContainer key={key}>
                  <Thumbnail
                    src={thumbnailUrl}
                    alt={`Thumbnail for ${mediaItem.title}`}
                    $shouldDisplayClear={key <= currentMove}
                    onLoad={() => markImageAsLoaded(thumbnailUrl)}
                    style={{
                      display: isItLoading ? "none" : "block",
                    }}
                  />
                  {isItLoading && <LoadingSpinner isAbsolute={true} />}
                </ThumbnailContainer>
              ) : (
                <ThumbnailContainer key={key}>
                  <Thumbnail
                    src={thumbnailUrl}
                    alt={`Thumbnail for ${mediaItem.title}`}
                    $shouldDisplayClear={key <= currentMove}
                    onLoad={() => markImageAsLoaded(thumbnailUrl)}
                    style={{
                      display: isItLoading ? "none" : "block",
                    }}
                  />
                  {isItLoading && <LoadingSpinner isAbsolute={true} />}
                </ThumbnailContainer>
              );
            })}
      </ThumbnailComponent>
    );
  };

  const formatDate = () => {
    const date = new Date();
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const currentGame =
    gameImagesData.find((game) => game.id === currentGameId) || {};

  useEffect(() => {
    if (isThinking) {
      const display = isDesktop
        ? constants.MESSAGE_THINKING
        : constants.MESSAGE_THINKING_MOBILE;
      const firstName = currentGame.name?.split(" ")[0] || "Default";
      const personalizedMessage = isDesktop
        ? `${firstName} ${display}`
        : `${firstName}${display}`;

      setStatusMessage({
        message: personalizedMessage,
        result: "thinking",
      });
    } else if (winner === "Draw") {
      setStatusMessage({ message: constants.MESSAGE_DRAW, result: "draw" });
    } else if (!winner && currentMove === 1) {
      setStatusMessage({
        message: constants.MESSAGE_FIRST_TURN,
        result: "playing",
      });
    } else if (!winner) {
      setStatusMessage({
        message: constants.MESSAGE_USER_TURN,
        result: "playing",
      });
    } else {
      // Update for win/loss
      setStatusMessage({
        message:
          winner === "X" ? constants.MESSAGE_WIN : constants.MESSAGE_LOSE,
        result: winner === "X" ? "win" : "loss",
      });
    }
  }, [isThinking, isDesktop, winner, currentMove, currentGame.name]);

  // Assuming currentGame is already defined and updated correctly
  const userName = currentGame.name || "Default Name";
  const firstMediaThumbnail =
    currentGame.media && currentGame.media[0]
      ? currentGame.media[0].gameMainThumbnail
      : "default-thumbnail.jpg";
  // Assuming currentGame.media is already defined and contains the media items
  const lastMediaThumbnail =
    currentGame.media && currentGame.media[currentGame.media.length - 1]
      ? currentGame.media[currentGame.media.length - 1].gameMainThumbnail
      : "default-thumbnail.jpg";

  const getEmoji = useCallback((player, currentVersion) => {
    return constants.MODES_CONFIG[currentVersion].getEmoji(player, false);
  }, []);

  const getUserAndComputerIcons = useCallback(() => {
    const userIcon = getEmoji("X", version);
    return { userIcon };
  }, [version, getEmoji]);

  const updateIcons = useCallback(
    (currentVersion) => {
      const icons = getUserAndComputerIcons(currentVersion);
      setUserIcon(icons.userIcon);
    },
    [getUserAndComputerIcons]
  );

  useEffect(() => {
    if (version) {
      updateIcons(version);
    }
  }, [version, updateIcons]);

  // Update icons at the start of each game
  const resetBoard = useCallback(() => {
    setBoard(initialBoard);
    setIsXNext(true);
    setWinner(null);
    setCurrentMove(0);
    setWinningSquares([]);
    setIsNewGame(true);
    setStatusMessage({
      message: constants.MESSAGE_FIRST_TURN,
      result: "playing",
    });
  }, []);

  const replayCurrentLevel = useCallback(() => {
    resetBoard();
    setIsNewGame(true);
    setGameConcluded(false);
  }, [resetBoard]);

  const findNewestUnplayedGame = useCallback(() => {
    // Start checking from game ID 1
    for (let gameId = 1; gameId <= highestAvailableGameId; gameId++) {
      const gameData = gameHistory[gameId];
      if (!gameData || gameData.result === null) {
        return gameId;
      }
    }
    return -1;
  }, [highestAvailableGameId, gameHistory]);

  // Add a state for newestUnplayedGameId
  const [newestUnplayedGameId, setNewestUnplayedGameId] = useState(-1);

  // Update newestUnplayedGameId when dependencies change
  useEffect(() => {
    const id = findNewestUnplayedGame();
    setNewestUnplayedGameId(id);
  }, [findNewestUnplayedGame]);

  useEffect(() => {
    const highestIdInData = Math.max(...gameImagesData.map((game) => game.id));
    setHighestAvailableGameId(highestIdInData);
  }, [gameImagesData]);

  const handleNextGame = useCallback(async () => {
    const storedHighestGameId = parseInt(
      localStorage.getItem(`highestAvailableGameId`)
    );
    const nextGameId = currentGameId + 1;

    if (nextGameId > storedHighestGameId) {
      openModalWithContent(constants.ModalContentTypes.END);
      return; // Stop execution since no more games are available
    }

    // Load the next game as the conditions are satisfied
    setIsVisible(false);
    const nextGameData = await fetchModels(nextGameId);
    if (nextGameData) {
      setGameImagesData((prevData) => [...prevData, nextGameData]);
      setCurrentGameId(nextGameId);
      setLastGamePlayed((prevMax) => Math.max(prevMax, nextGameId));
      resetBoard();
      setIsNewGame(true);
    } else {
      console.error(
        `handleNextGame Failed to fetch data for game ID ${nextGameId}`
      );
    }
    setIsLoading(false);
    setIsVisible(true);
  }, [currentGameId, resetBoard, setIsLoading, setIsVisible]);

  // Function to update game statistics
  const updateGameStats = (result) => {
    if (result === "X") {
      setUserWins((prevWins) => prevWins + 1);
    } else if (result === "O") {
      setUserLosses((prevLosses) => prevLosses + 1);
    } else if (result === "Draw") {
      setUserDraws((prevDraws) => prevDraws + 1);
    }
  };

  // Existing loadGameFromHistory function
  const loadGameFromHistory = useCallback(
    (gameId) => {
      const gameData = gameHistory[gameId];
      if (gameData) {
        setBoard(gameData.board);
        setWinner(gameData.result);
        setCurrentMove(gameData.moveCount || 0);
        setIsXNext(gameData.nextPlayer === "X");
        setGameConcluded(gameData.gameConcluded || false);
        setCurrentGameId(gameId);
      } else {
        resetBoard();
        setWinner(null);
        setCurrentMove(0);
        setGameConcluded(false);
        setCurrentGameId(gameId);
      }
    },
    [gameHistory, resetBoard]
  );

  useEffect(() => {
    loadGameFromHistory(currentGameId);
  }, [currentGameId, loadGameFromHistory]);

  const goToPreviousGame = useCallback(() => {
    if (currentGameId > 1) {
      const prevGameId = currentGameId - 1;
      setCurrentGameId(prevGameId);
      loadGameFromHistory(prevGameId);
      setGameConcluded(false);
      setIsNewGame(false);
      setIsLoading(false);
    }
  }, [currentGameId, loadGameFromHistory]);

  useEffect(() => {
    localStorage.setItem(`gameCount`, gameCount);
    localStorage.setItem(`userWins`, userWins);
    localStorage.setItem(`userLosses`, userLosses);
    localStorage.setItem(`userDraws`, userDraws);
    localStorage.setItem(`currentGameId`, currentGameId);
    localStorage.setItem(`gameHistory`, JSON.stringify(gameHistory));
    localStorage.setItem(`lastGamePlayed`, lastGamePlayed);
  }, [
    gameCount,
    userWins,
    userLosses,
    userDraws,
    currentGameId,
    gameHistory,
    lastGamePlayed,
  ]);

  // Update difficulty based on game JSON data
  const getDifficultyEmoji = (difficulty) =>
    difficultyEmojiMap[difficulty] || difficulty;

  useEffect(() => {
    const currentGame = gameImagesData.find(
      (game) => game.id === currentGameId
    );
    if (currentGame) {
      setDifficulty(currentGame.difficulty);
    }
  }, [currentGameId, gameImagesData]);

  const calculateWinner = useCallback((board) => {
    for (let i = 0; i < constants.WINNING_LINES.length; i++) {
      const [a, b, c] = constants.WINNING_LINES[i];
      if (board[a] && board[a] === board[b] && board[a] === board[c]) {
        setWinningSquares(constants.WINNING_LINES[i]);
        return board[a];
      }
    }
    setWinningSquares([]);
    return null;
  }, []);

  const minimax = useCallback(
    (newBoard, depth, isMaximizing) => {
      const winner = calculateWinner(newBoard);
      if (winner === "O") return 10 - depth;
      if (winner === "X") return depth - 10;
      if (isBoardFull(newBoard)) return 0;

      if (isMaximizing) {
        let bestScore = -Infinity;
        for (let i = 0; i < newBoard.length; i++) {
          if (newBoard[i] === null) {
            newBoard[i] = "O";
            let score = minimax(newBoard, depth + 1, false);
            newBoard[i] = null;
            bestScore = Math.max(score, bestScore);
          }
        }
        return bestScore;
      } else {
        let bestScore = Infinity;
        for (let i = 0; i < newBoard.length; i++) {
          if (newBoard[i] === null) {
            newBoard[i] = "X";
            let score = minimax(newBoard, depth + 1, true);
            newBoard[i] = null;
            bestScore = Math.min(score, bestScore);
          }
        }
        return bestScore;
      }
    },
    [calculateWinner]
  );

  // Memoize makeAIMove using useCallback
  const makeAIMove = useCallback(() => {
    const findMediumMove = (board) => {
      return -1;
    };

    const findRandomMove = (board) => {
      const availableMoves = board
        .map((val, idx) => (val === null ? idx : null))
        .filter((val) => val !== null);
      if (availableMoves.length === 0) return -1;
      return availableMoves[Math.floor(Math.random() * availableMoves.length)];
    };

    const findBestMove = (board, player) => {
      let bestScore = -Infinity;
      let move = null;

      for (let i = 0; i < board.length; i++) {
        if (board[i] === null) {
          board[i] = player;
          let score = minimax(board, 0, false);
          board[i] = null;
          if (score > bestScore) {
            bestScore = score;
            move = i;
          }
        }
      }
      return move;
    };

    setIsThinking(true);
    setTimeout(() => {
      let move;
      switch (difficulty) {
        case "easy":
          move = findRandomMove(board);
          break;
        case "medium":
          move = findMediumMove(board);
          if (move === -1) {
            move = findRandomMove(board);
          }
          break;
        case "hard":
          move = findBestMove(board, "O");
          break;
        default:
          move = findRandomMove(board);
      }

      if (move !== -1) {
        const newBoard = board.slice();
        newBoard[move] = "O";
        setBoard(newBoard);
        setIsXNext(true);
      }
      setIsThinking(false);
    }, constants.IS_THINKING_TIME);
  }, [board, difficulty, minimax]);

  const concludeGame = useCallback(
    (gameId, gameResult) => {
      const gameData = gameImagesData.find((game) => game.id === gameId);

      if (!gameData) {
        console.error("Game data not found for game ID:", gameId);
        return;
      }

      // Check if the game is already in the saved games history
      const isAlreadySaved = savedGamesImageData.some(
        (savedGame) => savedGame.id === gameId
      );

      let newSavedGameData = savedGamesImageData;
      if (!isAlreadySaved) {
        // Update local state only if the game is not already saved
        newSavedGameData = [...savedGamesImageData, gameData];
        setSavedGamesImageData(newSavedGameData);

        // Update local storage
        localStorage.setItem(
          "savedGamesImageData",
          JSON.stringify(newSavedGameData)
        );
      }

      // Optionally update other states and local storage (like game history)
      const newHistory = {
        ...gameHistory,
        [gameId]: { ...gameData, result: gameResult },
      };
      setGameHistory(newHistory);
      localStorage.setItem("gameHistory", JSON.stringify(newHistory));
    },
    [
      gameImagesData,
      savedGamesImageData,
      gameHistory,
      setSavedGamesImageData,
      setGameHistory,
    ]
  );

  useEffect(() => {
    const updateGameHistory = (result, nextPlayer) => {
      setGameHistory((prevHistory) => {
        const newHistory = { ...prevHistory };
        newHistory[currentGameId] = {
          board,
          result,
          moveCount: currentMove,
          nextPlayer,
          gameConcluded: true,
        };
        localStorage.setItem(`gameHistory`, JSON.stringify(newHistory));
        return newHistory;
      });
    };

    const win = calculateWinner(board);

    if (win || isBoardFull(board)) {
      const gameResult = win ? win : "Draw";
      setWinner(gameResult);

      if (!gameConcluded && isNewGame) {
        // Handle the conclusion of the game
        concludeGame(currentGameId, gameResult); // Add this line to save the concluded game data

        // Start countdown for the Next button
        setNextButtonCountdown(
          (constants.IS_LOADING_BETWEEN_GAMES + constants.IS_LOADING_TIMEOUT) /
            1000
        );

        // Trigger loading screen only for newly concluded games
        setTimeout(() => {
          setIsLoading(true);
        }, constants.IS_LOADING_TIMEOUT);

        const countdownInterval = setInterval(() => {
          setNextButtonCountdown((prevCountdown) => {
            const newCountdown = prevCountdown - 1;
            if (newCountdown <= 0) {
              clearInterval(countdownInterval);
              return 0;
            }
            return newCountdown;
          });
        }, 1000);

        updateGameStats(gameResult);
        updateGameHistory(gameResult, isXNext ? "O" : "X");
        setGameConcluded(true);
        setGameCount((prevCount) => prevCount + 1);
        setIsNewGame(false);

        // Update lastGamePlayed and save it to local storage
        localStorage.setItem(`lastGamePlayed`, currentGameId.toString());

        // Update local storage with new stats
        localStorage.setItem(`gameCount`, (gameCount + 1).toString());
        localStorage.setItem(
          `userWins`,
          (userWins + (gameResult === "X" ? 1 : 0)).toString()
        );
        localStorage.setItem(
          `userLosses`,
          (userLosses + (gameResult === "O" ? 1 : 0)).toString()
        );
        localStorage.setItem(
          `userDraws`,
          (userDraws + (gameResult === "Draw" ? 1 : 0)).toString()
        );
      } else {
        setIsLoading(false);
      }
    } else if (!isXNext && !winner) {
      makeAIMove();
    }
  }, [
    board,
    isXNext,
    makeAIMove,
    currentGameId,
    gameConcluded,
    currentMove,
    isNewGame,
    userWins,
    userLosses,
    userDraws,
    gameCount,
    calculateWinner,
    winner,
    concludeGame,
  ]);

  useEffect(() => {
    const storedGameHistory = JSON.parse(localStorage.getItem(`gameHistory`));
    if (storedGameHistory) {
      setGameHistory(storedGameHistory);
    }
  }, []);

  const [lastClickedIndex, setLastClickedIndex] = useState(null);

  const handleClick = useCallback(
    (index) => {
      if (isThinking || board[index] || winner) return;
      const newBoard = board.slice();
      newBoard[index] = "X";
      setBoard(newBoard);
      setIsXNext(false);
      setLastClickedIndex(index);
      setTimeout(() => setLastClickedIndex(null), 400);
      setCurrentMove(currentMove + 1);
    },
    [isThinking, board, winner, currentMove]
  );

  useEffect(() => {
    if (!isThinking) {
      setIsLoading(false);
    }
  }, [isThinking]);

  const isBoardFull = (board) => {
    return board.every((square) => square !== null);
  };
  const renderSquare = (index) => {
    const isEmpty = board[index] === null;
    const isGameActive = !winner && !isBoardFull(board);
    const hoverIcon = isEmpty && isXNext ? userIcon : "";
    const isActive = winningSquares.includes(index);
    const animationDelay = isActive ? 0.2 * winningSquares.indexOf(index) : 0;

    return (
      <Square
        key={index}
        onClick={() => handleClick(index)}
        $isActive={winningSquares.includes(index)}
        $isWinner={winner}
        $hoverIcon={hoverIcon}
        $isGameActive={isGameActive}
        $animationDelay={animationDelay}
        $isClicked={lastClickedIndex === index}
      >
        {getEmoji(board[index], version)}
      </Square>
    );
  };

  const handleVersionChange = (newVersion) => {
    setVersion(newVersion);
    localStorage.setItem("version", newVersion);

    // Update icons directly here
    const userIcon = getEmoji("X", newVersion);
    setUserIcon(userIcon);
  };

  // MODAL
  function closeModal() {
    setIsOpen(false);
  }

  // Assuming `gameImagesData` is an array of all games, sorted by id
  const lastGameId = Math.max(...gameImagesData.map((game) => game.id));

  return (
    <AppWrapper>
      {showSplashScreen && (
        <SplashScreenComponent
          isVisible={showSplashScreen}
          fadeOut={fadeOut}
          handleTransition={handleTransition}
          formatDate={formatDate}
          clearLocalStorage={clearLocalStorage}
          ModalContentTypes={constants.ModalContentTypes}
          newestUnplayedGameId={newestUnplayedGameId}
          loadGameFromHistory={loadGameFromHistory}
          setCurrentGameId={setCurrentGameId}
          totalGamesCount={totalGamesCount}
          highestAvailableGameId={highestAvailableGameId}
          setNewGamesAvailable={setNewGamesAvailable} // Pass this function
          newGamesAvailable={newGamesAvailable} // And the state
          NewGamesAvailableBanner={NewGamesAvailableBanner} // And the state
        />
      )}

      <ModalComponent
        isOpen={modalIsOpen}
        onClose={closeModal}
        currentModalContent={currentModalContent}
        currentGameId={currentGameId}
        gameCount={gameCount}
        userWins={userWins}
        userLosses={userLosses}
        userDraws={userDraws}
        board={board}
        version={version}
        winner={winner}
        userName={userName}
        firstMediaThumbnail={firstMediaThumbnail}
        lastMediaThumbnail={lastMediaThumbnail}
        currentGame={currentGame}
        clearLocalStorage={clearLocalStorage}
      />

      <GameScreen $isVisible={showGameScreen}>
        <LayoutContainer>
          <Layout>
            <HeaderContainer>
              <GameScreenLogo src={Logo} alt="Logo" />
              <HeaderActions>
                <HeaderMenu>
                  {/* <TooltipComponent
                    content={`${
                      (difficulty?.charAt(0)?.toUpperCase() || "") +
                      (difficulty?.slice(1)?.toLowerCase() || "")
                    }`}
                  >
                    <Button size="md" text={getDifficultyEmoji(difficulty)} />
                  </TooltipComponent> */}

                  <TooltipComponent content="How to Play">
                    <Button
                      size="md"
                      type="squish"
                      icon={IconCircleQuestion}
                      ariaLabel="How to Play"
                      onClick={() =>
                        openModalWithContent(constants.ModalContentTypes.HELP)
                      }
                    />
                  </TooltipComponent>

                  <TooltipComponent content="Stats">
                    <Button
                      size="md"
                      type="squish"
                      icon={IconChartSimple}
                      ariaLabel="Stats"
                      onClick={() =>
                        openModalWithContent(constants.ModalContentTypes.STATS)
                      }
                    />
                  </TooltipComponent>
                </HeaderMenu>

                <TooltipComponent content="You">
                  <ProfileContainer onClick={cycleMode}>
                    <ProfileEmoji>
                      {constants.MODES_CONFIG[version].emoji}
                    </ProfileEmoji>
                  </ProfileContainer>
                </TooltipComponent>
              </HeaderActions>
            </HeaderContainer>
            <MainContainer $isVisible={isVisible}>
              <MainContainerContent>
                <GameBoardContainer>
                  <GameBoard>
                    {isLoading && (
                      <GameBlockerContent
                        currentGameId={currentGameId}
                        gameImagesData={gameImagesData}
                      />
                    )}
                    <ActionText>
                      <Overlay result={statusMessage.result}>
                        {statusMessage.result === "thinking" && (
                          <LoadingSpinner isAbsolute={false} size="20px" />
                        )}
                        {statusMessage.message}
                      </Overlay>
                    </ActionText>

                    <GameBoardGrid>
                      {Array(9)
                        .fill(null)
                        .map((_, index) => renderSquare(index))}{" "}
                    </GameBoardGrid>
                    <NextGameButtonContainer>
                      <TooltipComponent
                        content="Previous Game"
                        placement="bottom"
                      >
                        <Button
                          size={isDesktop ? "lg" : "md"}
                          type="squish"
                          ariaLabel="Previous Game"
                          icon={IconBackward}
                          iconPosition="left"
                          onClick={goToPreviousGame}
                          disabled={currentGameId <= 1}
                        />
                      </TooltipComponent>

                      <TooltipComponent
                        content="Replay Game"
                        placement="bottom"
                      >
                        <Button
                          size={isDesktop ? "lg" : "md"}
                          type="squish"
                          icon={IconRefresh}
                          iconPosition="left"
                          ariaLabel="Replay Level"
                          onClick={replayCurrentLevel}
                          disabled={!gameHistory[currentGameId]}
                        />
                      </TooltipComponent>

                      <TooltipComponent
                        content="Newest Game"
                        placement="bottom"
                      >
                        <Button
                          size={isDesktop ? "lg" : "md"}
                          type="squish"
                          icon={IconForward}
                          iconPosition="right"
                          ariaLabel="Next"
                          onClick={handleNextGame}
                          disabled={
                            nextButtonCountdown > 0 ||
                            (!gameHistory[currentGameId]?.gameConcluded &&
                              (!gameHistory[currentGameId + 1] ||
                                !gameHistory[currentGameId + 1]
                                  ?.gameConcluded)) ||
                            (currentGameId === lastGameId &&
                              !gameHistory[currentGameId]?.gameConcluded)
                          }
                        />
                      </TooltipComponent>
                    </NextGameButtonContainer>
                  </GameBoard>

                  <MediaDisplayComponent
                    currentGame={currentGame}
                    currentMove={currentMove}
                    handleMainImageLoad={handleMainImageLoad}
                    mainImageLoading={mainImageLoading}
                    dataLoading={dataLoading}
                  />
                </GameBoardContainer>
                <SidebarComponent>
                  <CardComponent flexy={true} boxShadow>
                    <ModelDetails>
                      <GameIdTagComponent
                        text={currentGameId}
                        getDifficultyEmoji={getDifficultyEmoji}
                        difficulty={getDifficultyEmoji(difficulty)}
                      />
                      <GameInfoComponent game={currentGame} />
                    </ModelDetails>
                  </CardComponent>

                  <CardComponent title="Upcoming Moves" boxShadow>
                    {renderThumbnails()}
                  </CardComponent>
                </SidebarComponent>
              </MainContainerContent>
            </MainContainer>

            <ArchiveComponent
              currentGameId={currentGameId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              specialData={specialData}
              savedGamesImageData={savedGamesImageData}
              gameHistory={gameHistory}
              SPECIAL_CARD_NTH={constants.SPECIAL_CARD_NTH}
              PAGINATION_ITEMS_PER_PAGE={constants.PAGINATION_ITEMS_PER_PAGE}
            />

            <Footer openModalWithContent={openModalWithContent} />
          </Layout>
        </LayoutContainer>
      </GameScreen>
    </AppWrapper>
  );
};

export default App;
