import { API_KEY, BASE_URL } from "./constants.js";

const fetchPageContentById = async (pageIds) => {
  // console.time("fetchPageContentById");
  try {
    const fetchPromises = pageIds.map((id) =>
      fetch(`${BASE_URL}/pages/${id}`, {
        headers: {
          "X-API-Key": API_KEY,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch page with ID: ${id}`);
          }
          return response.json();
        })
        .then((pageData) => ({
          id,
          title: pageData?.title?.rendered || "Untitled",
          content: pageData?.content?.rendered || "Content not found",
        }))
        .catch((error) => ({
          id,
          error: error.message,
          title: "Error",
          content: "Could not fetch content",
        }))
    );

    const responses = await Promise.all(fetchPromises);
    const responseMap = responses.reduce((acc, page) => {
      acc[page.id] = { title: page.title, content: page.content };
      return acc;
    }, {});

    // console.timeEnd("fetchPageContentById");
    return responseMap;
  } catch (error) {
    // console.error("Error fetching page content:", error);
    // console.timeEnd("fetchPageContentById");
    return {}; // Return empty object if there's an error
  }
};

export default fetchPageContentById;
