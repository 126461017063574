import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import { media } from "../components/Breakpoints";
import "@fontsource-variable/outfit";

export const GlobalStyle = createGlobalStyle`


  body {
    font-family: 'Outfit Variable', sans-serif;
    margin: 0;
    padding: 0;
    font-size: 15px;
    color: #fff;
    background-color: #000;

    ${media.sm`
    font-size: 16px;
    `}
  }

  a {
    text-decoration: none;
    color: #aeb5f7;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    line-height: 24px;
  }

  h1, h2, h3, h4, h5 {
    margin-top: 0;
    color: #ffffff;
  }
  
  .no-scroll {
    overflow: hidden;
  }  

  [data-tippy-root] .tippy-svg-arrow {
    fill: #fff;
    color: black;
  }
  
  [data-tippy-root][data-placement^='top'] .tippy-arrow {
    border-top-color: #fff;
  }

  [data-tippy-root][data-placement^='bottom'] .tippy-arrow {
    border-bottom-color: #fff;
  }

  [data-tippy-root][data-placement^='left'] .tippy-arrow {
    border-left-color: #fff;
  }
  
  [data-tippy-root][data-placement^='right'] .tippy-arrow {
    border-right-color: #fff;
  }
`;

export const ScreenReaderOnly = styled.span`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
`;

export const GlobalStyleWrapper = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  );
};
