import React from "react";
import styled from "styled-components";
import { useImageLoading } from "../ImageLoadingContext"; // Ensure you import this

// Components
import LoadingSpinner from "./Spinner";
import MediaDisplay from "./MediaDisplay";

// Utilities
import { media } from "./Breakpoints";
import * as constants from "../constants";

const MediaDisplayContainer = styled.div`
  position: relative;
  height: auto;
  min-width: ${constants.MEDIA_WIDTH_SMALL}px;

  ${media.xs`
      min-width: ${constants.MEDIA_WIDTH}px;
    `}

  &:before {
    transition: opacity 0.3s ease-in-out;
    content: "";
    background-color: #ffe8d8;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    position: absolute;
  }

  &:hover &:before {
    opacity: 1;
  }
`;

const MediaDisplayComponent = ({
  currentGame,
  currentMove,
  handleMainImageLoad,
  dataLoading,
}) => {
  const { isImageLoaded } = useImageLoading(); // Use the context
  const media = currentGame.media && currentGame.media[currentMove];

  const mainImageLoading = media
    ? !isImageLoaded(media.gameMainThumbnail || media.source)
    : true;

  return (
    <MediaDisplayContainer>
      {dataLoading || !media ? (
        <LoadingSpinner />
      ) : (
        <MediaDisplay
          media={media}
          onLoad={handleMainImageLoad}
          mainImageLoading={mainImageLoading}
        />
      )}
    </MediaDisplayContainer>
  );
};

export default MediaDisplayComponent;
