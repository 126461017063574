import React from "react";
import styled from "styled-components";
import { media } from "../components/Breakpoints";
import ArchiveCard from "../components/ArchiveCard";
import Button from "../components/Button";
import CardComponent from "../components/Card";
import GameInfoComponent from "../components/GameInfoComponent";
import { ReactComponent as IconArrowLeftSolid } from "../assets/svg/ArrowLeftSolid.svg";
import { ReactComponent as IconArrowRightSolid } from "../assets/svg/ArrowRightSolid.svg";

const ArchiveHeader = styled.div`
  gap: 16px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`;

const ArchiveContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ArchiveImageCardList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  margin: 0;
  padding: 0;
  gap: 24px;
  width: 100%;
  justify-content: center;

  ${media.sm`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.md`
    grid-template-columns: repeat(4, 1fr);
  `}

  ${media.lg`
    grid-template-columns: repeat(5, 1fr);
  `}
`;

const ArchiveHeaderActions = styled.div``;

const ArchivePagination = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const ArchivePaginationCount = styled.h3`
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
`;
const ArchivePaginationButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const ArchiveComponent = ({
  savedGamesImageData,
  specialData,
  currentPage,
  setCurrentPage,
  SPECIAL_CARD_NTH,
  PAGINATION_ITEMS_PER_PAGE,
  currentGameId,
}) => {
  let adIndex = 0;
  const itemsToDisplay = savedGamesImageData.flatMap((game, index) => {
    const content = [{ type: "game", data: game }];
    if ((index + 1) % SPECIAL_CARD_NTH === 0) {
      content.push({
        type: "ad",
        data: specialData[adIndex % specialData.length],
      });
      adIndex++;
    }
    return content;
  });

  const totalPages = Math.ceil(
    itemsToDisplay.length / PAGINATION_ITEMS_PER_PAGE
  );
  const startIndex = (currentPage - 1) * PAGINATION_ITEMS_PER_PAGE;
  const endIndex = startIndex + PAGINATION_ITEMS_PER_PAGE;
  const currentPageItems = itemsToDisplay.slice(startIndex, endIndex);

  return (
    currentPageItems.length !== 0 && (
      <CardComponent padding="lg" title="History" boxShadow>
        {totalPages > 1 && (
          <ArchiveHeader>
            <ArchiveHeaderActions>
              <ArchivePagination>
                <>
                  <ArchivePaginationCount>
                    {currentPage} of {totalPages}
                  </ArchivePaginationCount>
                  <ArchivePaginationButtons>
                    <Button
                      size="md"
                      type="squish"
                      icon={IconArrowLeftSolid}
                      ariaLabel="Previous Page"
                      onClick={() =>
                        setCurrentPage(Math.max(1, currentPage - 1))
                      }
                      disabled={currentPage === 1}
                    />
                    <Button
                      size="md"
                      type="squish"
                      icon={IconArrowRightSolid}
                      ariaLabel="Next Page"
                      onClick={() =>
                        setCurrentPage(Math.min(totalPages, currentPage + 1))
                      }
                      disabled={currentPage === totalPages}
                    />
                  </ArchivePaginationButtons>
                </>
              </ArchivePagination>
            </ArchiveHeaderActions>
          </ArchiveHeader>
        )}

        <ArchiveContent>
          <ArchiveImageCardList>
            {currentPageItems.map((item, index) => {
              if (item.type === "game") {
                const game = item.data;
                const firstMediaItem = Object.values(game.media || {})[0] || {};
                const firstImageUrl =
                  firstMediaItem.gameArchiveThumbnail ||
                  (firstMediaItem.type === "image"
                    ? firstMediaItem.source
                    : "");

                if (!firstImageUrl) {
                  return null;
                }

                return (
                  <ArchiveCard
                    key={`game-${game.id}`}
                    isActive={game.id === currentGameId}
                    onClick={() => {
                      if (game.website && game.website.link) {
                        window.open(game.website.link, "_blank");
                      } else {
                        window.open("https://candy.ai?via=chat", "_blank");
                      }
                    }}
                    imageUrl={firstImageUrl}
                    imageAlt={`Game ${game.id}`}
                    websiteUrl={game.website.link + game.website.affiliate}
                    showContent={true}
                    content={
                      <GameInfoComponent
                        isArchiveCard={true}
                        game={game}
                        showContent={false}
                      />
                    }
                  />
                );
              } else if (item.type === "ad") {
                const ad = item.data;

                if (ad?.url && ad?.media && ad?.name) {
                  return (
                    <ArchiveCard
                      key={`ad-${index}`}
                      onClick={() => window.open(ad.url, "_blank")}
                      imageUrl={ad.media}
                      imageAlt={`${ad.name}`}
                      showContent={true}
                      type={ad.type}
                      content={
                        <>
                          <p>{ad.name}</p>
                        </>
                      }
                    />
                  );
                }
                return null;
              }
              return null;
            })}
          </ArchiveImageCardList>
        </ArchiveContent>
      </CardComponent>
    )
  );
};

export default ArchiveComponent;
