import React from "react";
import styled, { css } from "styled-components";
import LoadingSpinner from "../components/Spinner";

const ArchiveCardItem = styled.li`
  position: relative;
  margin-bottom: 15px;
  display: flex;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  border-top-right-radius: 16px;
  gap: 8px;
  max-width: 100%;
  overflow: hidden;
  border-color: ${({ $isActive }) => ($isActive ? "#000" : "#363636")};
  background-color: ${({ $isActive }) => ($isActive ? "#000" : "#363636")};

  &:hover {
    border-color: ${({ $isActive }) => ($isActive ? "#000" : "#000")};
    background-color: ${({ $isActive }) => ($isActive ? "#000" : "#000")}
`;

const ArchiveCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  align-items: flex-start;

  p {
    margin: 0;
    padding: 0;
    line-height: 1;
  }
`;

const ArchiveCardContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  ${({ $type }) =>
    $type === "fullHeight" &&
    css`
      max-height: 100%;
      height: 100%;
    `}
`;

const ArchiveCardInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ArchiveCardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1.3;
`;

const ArchiveCard = ({
  isActive,
  onClick,
  imageUrl,
  imageAlt,
  showContent,
  content,
  isLoading,
  type,
}) => {
  return (
    <ArchiveCardItem
      className={type}
      $isActive={isActive}
      $type={type}
      onClick={onClick}
    >
      <ArchiveCardInner>
        <ArchiveCardContainer $type={type}>
          {isLoading && <LoadingSpinner $isAbsolute={true} />}
          <ArchiveCardImage
            loading="lazy"
            src={imageUrl}
            alt={imageAlt}
            style={{ display: isLoading ? "none" : "block" }}
          />
        </ArchiveCardContainer>

        {showContent && type !== "fullHeight" && (
          <ArchiveCardContent>{content}</ArchiveCardContent>
        )}
      </ArchiveCardInner>
    </ArchiveCardItem>
  );
};

export default ArchiveCard;
