// Initialize allMessageSets with the timestamp functions called
export const allMessageSets = [
  [
    {
      text: "I'm at the bar drinking whiskey, heading home, feeling frisky... 🥃💋",
      isSender: true,
    },
  ],
  [
    {
      text: "I had the craziest dream about us last night... 😏",
      isSender: true,
    },
  ],
  [
    {
      text: "Planning a fun night, make sure you're ready for a surprise! 🎉👄",
      isSender: true,
    },
  ],
  [
    {
      text: "I'm wearing the gift you gave me... Can you guess which one? 😜",
      isSender: true,
    },
  ],
  [
    {
      text: "Feeling a bit naughty today... Should I stop or should I continue? 😈",
      isSender: true,
    },
  ],
  [
    {
      text: "Miss your touch... can't wait till we're together again. 🖤",
      isSender: true,
    },
  ],
  [
    {
      text: "Got a secret to tell you later... make sure you're alone 😉",
      isSender: true,
    },
  ],
  [
    {
      text: "If I were with you right now, what would we be doing? 🤔💭",
      isSender: true,
    },
  ],
  [
    {
      text: "Counting down the minutes until I see you. Every second feels like an hour. ⏳💋",
      isSender: true,
    },
  ],
  [
    {
      text: "Hope you're ready for later, I've got plans for us... 🌌",
      isSender: true,
    },
  ],
  [
    {
      text: "Thinking about last night... Can we do it again? 🔁😉",
      isSender: true,
    },
  ],
  [
    {
      text: "Last night was unforgettable. I need a round two... soon. 🌙✨",
      isSender: true,
    },
  ],
  [
    {
      text: "How about a midnight swim tonight? Just you, me, and the stars. 🏊‍♂️✨",
      isSender: true,
    },
  ],
  [
    {
      text: "Just found that picture of us from Vegas... 🔥 It's making me all kinds of nostalgic.",
      isSender: true,
    },
  ],
  [
    {
      text: "I've been naughty at work today... thinking of you. 💼😈",
      isSender: true,
    },
  ],
  [
    {
      text: "Your scent is still on my pillow... I'm tempted to steal your shirt next time. 👕💞",
      isSender: true,
    },
  ],
  [
    {
      text: "Dinner was amazing, but I'm craving something sweeter now... any ideas? 🍰😉",
      isSender: true,
    },
  ],
  [
    {
      text: "Can't focus at work, I keep replaying our last night over and over in my head. 💭💋",
      isSender: true,
    },
  ],
  [
    {
      text: "If I said you have a beautiful body, would you hold it against me tonight? 🤷‍♂️💓",
      isSender: true,
    },
  ],
  [
    {
      text: "Let's skip the movie tonight. I have something else in mind... 📽️❌",
      isSender: true,
    },
  ],
  [
    {
      text: "That dress you wore last night... Wow. When can I see it again? Or better yet, not see it? 😏",
      isSender: true,
    },
  ],
  [
    {
      text: "I bought something today that I think you'll enjoy. Hint: it's not clothes. 🎁😜",
      isSender: true,
    },
  ],
  [
    {
      text: "I'm planning a surprise for you. It involves a blindfold and your favorite playlist. 🎶😉",
      isSender: true,
    },
  ],
  [
    {
      text: "I've got a bottle of your favorite wine and a confession to make... 🍷👀",
      isSender: true,
    },
  ],
  [
    {
      text: "Hey, I'm feeling a little adventurous today. Interested in a challenge? 🌟🔥",
      isSender: true,
    },
  ],
  [
    {
      text: "Remember our first date? How about we recreate it tonight, with a twist? 🔄💡",
      isSender: true,
    },
  ],
  [
    {
      text: "Just left the gym, and guess what? I found your favorite spot for our next rendezvous. 🏋️‍♂️💘",
      isSender: true,
    },
  ],
  [
    {
      text: "How do you feel about playing hooky with me tomorrow? I've got a day planned you won't forget. 🌞🚗",
      isSender: true,
    },
  ],
  [
    {
      text: "Feeling bold tonight... How about we try something new? 🎲👀",
      isSender: true,
    },
  ],
  [
    {
      text: "Just took a hot shower and thought of you... maybe next time you should join me. 🚿😉",
      isSender: true,
    },
  ],
  [
    {
      text: "I'm cooking dinner tonight... and dessert might just be on me 😉🍫",
      isSender: true,
    },
  ],
  [
    {
      text: "Do you prefer red or white... or should I just bring both? 🍷🥂",
      isSender: true,
    },
  ],
  [
    {
      text: "I can't stop thinking about your lips, your smile... all of you. 😍",
      isSender: true,
    },
  ],
  [
    {
      text: "Just got out of a boring meeting and all I could think about was our last adventure. 📊😣➡️😈",
      isSender: true,
    },
  ],
  [
    {
      text: "I've got two tickets to paradise, pack your bags we leave tonight! 🎟️✈️",
      isSender: true,
    },
  ],
  [
    {
      text: "What are you wearing right now? Asking for a friend... 😜",
      isSender: true,
    },
  ],
  [
    {
      text: "How long till you're here? I'm counting every heartbeat. 💓⏳",
      isSender: true,
    },
  ],
  [
    {
      text: "Let’s make this a night we won’t remember with friends we’ll never forget. 🎉👯",
      isSender: true,
    },
  ],
  [
    {
      text: "Just a reminder: You are my favorite distraction. 😘",
      isSender: true,
    },
  ],
  [
    {
      text: "Let's get lost somewhere between reality and all we've ever dreamed. 🗺️💭",
      isSender: true,
    },
  ],
  [
    {
      text: "Your kiss is on my list of the best things in life. 💋",
      isSender: true,
    },
  ],
  [
    {
      text: "Woke up craving you... what are you doing to me? 🛌💭",
      isSender: true,
    },
  ],
  [
    {
      text: "Just thinking about our next rendezvous has me all excited. 📅💖",
      isSender: true,
    },
  ],
  [
    {
      text: "Tonight's agenda: uncensored fun. You in? 📜😉",
      isSender: true,
    },
  ],
  [
    {
      text: "Let's make some memories we'll both be too bashful to share. 🙈🎶",
      isSender: true,
    },
  ],
];
