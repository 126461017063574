export const API_KEY =
  "39951289589125981581295812598129821589125912589125951218259";

// Determine if app is running on localhost
export const isLocalhost = ["localhost", "127.0.0.1", "strip.local"].includes(
  window.location.hostname
);

// Set the BASE_URL based on whether it's localhost or production
export const BASE_URL = isLocalhost
  ? "https://strip.local/wp-json/wp/v2"
  : "https://striptactoe.com/wordpress/wp-json/wp/v2";

export const WINNING_LINES = [
  [0, 1, 2], // Horizontal
  [3, 4, 5],
  [6, 7, 8],
  [0, 3, 6], // Vertical
  [1, 4, 7],
  [2, 5, 8],
  [0, 4, 8], // Diagonal
  [2, 4, 6],
];

export const BREAKPOINTS = {
  xs: 540,
  sm: 768,
  md: 992,
  lg: 1200,
};

// Branding
export const BRAND_COLORS = {
  primaryColor: "#000",
  primaryColorHover: "#000",
  primaryColorActive: "#000",
  primaryColorDisabled: "#000",
  primaryBackground: "#7ce3cb",
  primaryBackgroundHover: "#7ce3cb",
  primaryBackgroundActive: "#7ce3cb",
  primaryBackgroundDisabled: "#7ce3cb",

  secondaryColor: "#000",
  secondaryColorHover: "#000",
  secondaryColorActive: "#000",
  secondaryColorDisabled: "#000",
  secondaryBackground: "#ffc166",
  secondaryBackgroundHover: "#ffc166",
  secondaryBackgroundActive: "#ffc166",
  secondaryBackgroundDisabled: "#ffc166",

  outlineColor: "#000",
  outlineColorHover: "#000",
  outlineColorActive: "#000",
  outlineColorDisabled: "#000",
  outlineBackground: "#ffc166",
  outlineBackgroundHover: "#ffc166",
  outlineBackgroundActive: "#ffc166",
  outlineBackgroundDisabled: "#ffc166",
};

export const COLOR_WIN = "#90EE90";
export const COLOR_LOSS = "#FFCCCB";
export const COLOR_DRAW = "#FFFFAD";
export const MEDIA_WIDTH_SMALL = 144;
export const MEDIA_WIDTH = 288;

export const MESSAGE_THINKING = "is thinking...";
export const MESSAGE_THINKING_MOBILE = "'s move";
export const MESSAGE_DRAW = "It's a Draw";
export const MESSAGE_WIN = "You Win";
export const MESSAGE_LOSE = "You Lose";
export const MESSAGE_USER_TURN = "Your move";
export const MESSAGE_FIRST_TURN = "Make the first move";

export const TRANSITION_DURATION = 300; // Transition duration variable
export const PAGINATION_ITEMS_PER_PAGE = 10; // How many items appear in archive per page
export const IS_THINKING_TIME = 1500; // How long the computer takes to think in ms
export const IS_LOADING_TIMEOUT = 3000; // Timeout after each game has ended
export const IS_LOADING_BETWEEN_GAMES = 5000; // Loading between each game
export const SPECIAL_CARD_NTH = 0; // Show an ad card every nth game in the archives

export const ModalContentTypes = {
  STATS: { type: "stats", title: "Statistics" },
  HELP: { type: "help", title: "How to Play" },
  SHARE: { type: "share", title: "Share" },
  END: { type: "end", title: "Game Over" },
  NONE: { type: null, title: "" },
};

export const MODES_CONFIG = {
  fun: {
    emoji: "❌",
    getEmoji: (cell, isEmojiGrid) =>
      cell === "X" ? "❌" : cell === "O" ? "⭕️" : isEmojiGrid ? "⬛" : "",
  },
  wild: {
    emoji: "😈",
    getEmoji: (cell, isEmojiGrid) =>
      cell === "X" ? "😈" : cell === "O" ? "😄" : isEmojiGrid ? "⬛" : "",
  },
  awesome: {
    emoji: "🍆",
    getEmoji: (cell, isEmojiGrid) =>
      cell === "X" ? "🍆" : cell === "O" ? "🍑" : isEmojiGrid ? "⬛" : "",
  },
};

export const FOOTER_MENU = [
  3, // Privacy Policy
  2119, // Terms and Conditions
  357, // 2257
  2122, // Cookie Policy
  359, // Contact
];
