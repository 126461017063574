import { useState, useEffect } from "react";
import { css } from "styled-components";
import { BREAKPOINTS } from "../constants";

// Helper function to debounce another function
function debounce(fn, ms = 300) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn.apply(this, args), ms);
  };
}

export const media = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${BREAKPOINTS[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

function useBreakpoint() {
  const [screenSize, setScreenSize] = useState({
    isMobile: window.innerWidth < BREAKPOINTS.sm,
    isTablet:
      window.innerWidth >= BREAKPOINTS.sm && window.innerWidth < BREAKPOINTS.md,
    isDesktop: window.innerWidth >= BREAKPOINTS.md,
    isLargeDesktop: window.innerWidth >= BREAKPOINTS.lg,
  });

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      const width = window.innerWidth;
      setScreenSize({
        isMobile: width < BREAKPOINTS.sm,
        isTablet: width >= BREAKPOINTS.sm && width < BREAKPOINTS.md,
        isDesktop: width >= BREAKPOINTS.md,
        isLargeDesktop: width >= BREAKPOINTS.lg,
      });
    }, 250);

    window.addEventListener("resize", debouncedHandleResize);
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, []);

  return screenSize;
}

export default useBreakpoint;
