import React from "react";
import styled from "styled-components";
import * as constants from "../constants";

// Styled components definitions
const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const BarLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #444;
`;

const BarLineText = styled.div`
  min-width: 50px;
  font-size: 14px;
  font-weight: 500;
`;

const BarLineGames = styled.div`
  color: #fff;
  min-width: 50px;
`;

const BarLinePercentage = styled.div`
  color: #fff;
`;

const BarLineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  flex: 1;
  width: 100%;
  line-height: 1;
`;

// Function to determine the background color
const getBackgroundColor = (barType) => {
  switch (barType) {
    case "win":
      return constants.COLOR_WIN;
    case "loss":
      return constants.COLOR_LOSS;
    case "draw":
      return constants.COLOR_DRAW;
    default:
      return "transparent";
  }
};

const Bar = styled.span`
  display: flex;
  height: 12px;
  border-radius: 16px;
  background-color: ${({ $barType }) => getBackgroundColor($barType)};
  width: ${({ $percentage }) => `${$percentage}%`};
  overflow: hidden;
`;

// StatsBarContainer component
const StatsBarContainer = ({ userWins, userLosses, userDraws }) => {
  // Calculate percentages
  const totalGames = userWins + userLosses + userDraws;
  const winPercentage = totalGames > 0 ? (userWins / totalGames) * 100 : 0;
  const lossPercentage = totalGames > 0 ? (userLosses / totalGames) * 100 : 0;
  const drawPercentage = totalGames > 0 ? (userDraws / totalGames) * 100 : 0;

  return (
    <BarContainer>
      <BarLine>
        <BarLineText>Wins</BarLineText>
        <BarLineContainer>
          <BarLineGames>{userWins}</BarLineGames>
          {userWins !== 0 && (
            <Bar $barType="win" $percentage={winPercentage.toFixed(1)} />
          )}
          <BarLinePercentage>{winPercentage.toFixed(1)}%</BarLinePercentage>
        </BarLineContainer>
      </BarLine>
      <BarLine>
        <BarLineText>Losses</BarLineText>
        <BarLineContainer>
          <BarLineGames>{userLosses}</BarLineGames>
          {userLosses !== 0 && (
            <Bar $barType="loss" $percentage={lossPercentage.toFixed(1)} />
          )}
          <BarLinePercentage>{lossPercentage.toFixed(1)}%</BarLinePercentage>
        </BarLineContainer>
      </BarLine>
      <BarLine>
        <BarLineText>Draws</BarLineText>
        <BarLineContainer>
          <BarLineGames>{userDraws}</BarLineGames>
          {userDraws !== 0 && (
            <Bar $barType="draw" $percentage={drawPercentage.toFixed(1)} />
          )}
          <BarLinePercentage>{drawPercentage.toFixed(1)}%</BarLinePercentage>
        </BarLineContainer>
      </BarLine>
    </BarContainer>
  );
};

export default StatsBarContainer;
