import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../components/Button";
import GameHelpBoard from "./GameHelpBoard";

const GameHelpContentContainer = styled.div``;

const GameHelpContent = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const GameHelpTitle = styled.h2``;

const GameHelpParagraph = styled.p``;

const GameHelpButton = styled(Button)`
  margin: 16px 0 0;
`;

const GameHelp = ({ clearLocalStorage }) => {
  const [clickCount, setClickCount] = useState(0);
  const [buttonText, setButtonText] = useState("Clear All Data");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    let timeout;
    if (clickCount === 3) {
      setButtonText("Data has been reset");
      setIsButtonDisabled(true);
      timeout = setTimeout(() => {
        clearLocalStorage();
        setClickCount(0);
      }, 1500);
    }

    return () => clearTimeout(timeout);
  }, [clickCount, clearLocalStorage]);

  const handleButtonClick = () => {
    const newClickCount = clickCount + 1;
    setClickCount(newClickCount);

    if (newClickCount === 1) {
      setButtonText("Proceed with caution!");
    } else if (newClickCount === 2) {
      setButtonText("Final warning...");
    }
  };

  return (
    <GameHelpContentContainer>
      <GameHelpContent>
        <GameHelpTitle>Game Play</GameHelpTitle>
        <GameHelpParagraph>
          The object of <strong>Strip Tac Toe</strong> is to place three like
          emojis in a row. The game is played on a 3 x 3 board, alternating
          turns.
        </GameHelpParagraph>
      </GameHelpContent>
      <GameHelpContent>
        <GameHelpTitle>Daily Updates</GameHelpTitle>
        <GameHelpParagraph>
          We are constantly updating the game with additional levels. Check back
          daily!
        </GameHelpParagraph>
      </GameHelpContent>
      <GameHelpContent>
        <GameHelpBoard />
      </GameHelpContent>
      <GameHelpContent>
        <GameHelpTitle>Reset Progress</GameHelpTitle>
        <GameHelpParagraph>
          This will reset <strong>all</strong> your progress.
          <GameHelpButton
            type="outline"
            text={buttonText}
            onClick={handleButtonClick}
            disabled={isButtonDisabled}
          />
        </GameHelpParagraph>
      </GameHelpContent>
    </GameHelpContentContainer>
  );
};

export default GameHelp;
