import styled from "styled-components";
import useBreakpoint, { media } from "../components/Breakpoints";
import * as constants from "../constants";

const GameHelpGameboardContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  gap: 16px;
`;

const GameHelpGameboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const GameHelpGameboard = styled.div`
  gap: 4px;
  display: grid;
  grid-template-columns: repeat(3, 20px);
  grid-template-rows: repeat(3, 20px);
  justify-content: center;
  position: relative;

  ${media.xs`
    gap: 8px;
    grid-template-columns: repeat(3, 32px);
    grid-template-rows: repeat(3, 32px);
  `}
`;

const GameHelpGameboardText = styled.div``;

const GameHelpGameboardSquare = styled.div`
  border-radius: 4px;
  background-color: ${(props) => {
    if (props.className === "win") return constants.COLOR_WIN;
    if (props.className === "loss") return constants.COLOR_LOSS;
    if (props.className === "draw") return constants.COLOR_DRAW;
    return "#d8efff";
  }};
`;

const GameHelpBoard = () => {
  const { isMobile } = useBreakpoint();
  const boardExamples = [
    {
      squares: ["", "", "win", "", "win", "", "win", "", ""],
      label: isMobile ? "Win" : "Green is a win",
    },
    {
      squares: ["loss", "loss", "loss", "", "", "", "", "", ""],
      label: isMobile ? "Loss" : "Red is a loss",
    },
    {
      squares: ["draw", "", "", "draw", "", "", "draw", "", ""],
      label: isMobile ? "Draw" : "Yellow is a draw",
    },
  ];

  return (
    <GameHelpGameboardContainerWrapper>
      {boardExamples.map((example, index) => (
        <GameHelpGameboardContainer key={index}>
          <GameHelpGameboard>
            {example.squares.map((square, idx) => (
              <GameHelpGameboardSquare key={idx} className={square} />
            ))}
          </GameHelpGameboard>
          <GameHelpGameboardText>{example.label}</GameHelpGameboardText>
        </GameHelpGameboardContainer>
      ))}
    </GameHelpGameboardContainerWrapper>
  );
};

export default GameHelpBoard;
