import React, { createContext, useContext, useState, useEffect } from "react";

const ImageLoadingContext = createContext({
  isImageLoaded: () => false,
  markImageAsLoaded: () => {},
});

export const ImageLoadingProvider = ({ children }) => {
  const [loadedImages, setLoadedImages] = useState(() => {
    // Retrieve the loaded images from localStorage and parse them
    const savedImages = JSON.parse(localStorage.getItem("loadedImages")) || {};
    return new Set(Object.keys(savedImages).filter((key) => savedImages[key]));
  });

  useEffect(() => {
    // Whenever loadedImages changes, update localStorage
    const imagesToSave = {};
    loadedImages.forEach((image) => {
      imagesToSave[image] = true;
    });
    localStorage.setItem("loadedImages", JSON.stringify(imagesToSave));
  }, [loadedImages]);

  const markImageAsLoaded = (imageSrc) => {
    setLoadedImages((prev) => new Set(prev.add(imageSrc)));
  };

  const isImageLoaded = (imageSrc) => {
    return loadedImages.has(imageSrc);
  };

  return (
    <ImageLoadingContext.Provider value={{ isImageLoaded, markImageAsLoaded }}>
      {children}
    </ImageLoadingContext.Provider>
  );
};

export const useImageLoading = () => useContext(ImageLoadingContext);
