import React from "react";
import styled from "styled-components";
import StatsBarContainer from "../components/StatsBar";
import { media } from "../components/Breakpoints";

const GameStatsBoxWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const GameStatsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const GameStatsBox = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 4px;
  background-color: #222;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px;
  width: 100%;
`;
const GameStatsTitle = styled.h3`
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  padding: 0;

  span {
    display: block;

    ${media.md`
    display: inline;
    `}
  }
`;

const GameStatsNumber = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

const GameStatsComponent = ({
  currentGameId,
  gameCount,
  userWins,
  userLosses,
  userDraws,
}) => {
  return (
    <GameStatsContent>
      <GameStatsBoxWrapper>
        <GameStatsBox>
          <GameStatsTitle>
            Current <span>Game</span>
          </GameStatsTitle>
          <GameStatsNumber>{currentGameId}</GameStatsNumber>
        </GameStatsBox>

        <GameStatsBox>
          <GameStatsTitle>
            Daily <span>Streak</span>
          </GameStatsTitle>
          <GameStatsNumber>{localStorage.getItem("streak")}</GameStatsNumber>
        </GameStatsBox>

        <GameStatsBox>
          <GameStatsTitle>
            Games <span>Played</span>
          </GameStatsTitle>
          <GameStatsNumber>{gameCount}</GameStatsNumber>
        </GameStatsBox>
      </GameStatsBoxWrapper>

      <StatsBarContainer
        userWins={userWins}
        userLosses={userLosses}
        userDraws={userDraws}
      />
    </GameStatsContent>
  );
};

export default GameStatsComponent;
