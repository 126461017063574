import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ImageLoadingProvider } from "./ImageLoadingContext";
import { GlobalStyleWrapper } from "./components/GlobalStyle";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ImageLoadingProvider>
      <GlobalStyleWrapper>
        <App />
      </GlobalStyleWrapper>
    </ImageLoadingProvider>
  </React.StrictMode>
);
