import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import useBreakpoint from "./Breakpoints";

// TooltipComponent accepts two props: the tooltip content and children
const TooltipComponent = ({ content, children, placement }) => {
  const { isDesktop } = useBreakpoint();

  // Render the Tippy component only on desktop; otherwise, render children directly
  return isDesktop ? (
    <Tippy content={content} placement={placement}>
      {children}
    </Tippy>
  ) : (
    <>{children}</>
  );
};

export default TooltipComponent;
