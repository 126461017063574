import React from "react";
import styled from "styled-components";
import LoadingSpinner from "./Spinner";
import { useImageLoading } from "../ImageLoadingContext"; // Import the context

// Utilities
import { media } from "./Breakpoints";
import * as constants from "../constants";

// Styled components
const ImageDisplayContainerWrapper = styled.div`
  display: flex;
  height: 100%;
  min-width: ${constants.MEDIA_WIDTH_SMALL}px;

  ${media.xs`
      min-width: ${constants.MEDIA_WIDTH}px;
    `}
`;

const ImageDisplayContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  width: ${constants.MEDIA_WIDTH_SMALL}px;
  min-width: ${constants.MEDIA_WIDTH_SMALL}px;

  ${media.xs`
      width: ${constants.MEDIA_WIDTH}px;
      min-width: ${constants.MEDIA_WIDTH}px;
    `}
`;
const ImageDisplay = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoElement = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

// MediaDisplay component
const MediaDisplay = ({ media, onLoad }) => {
  const { markImageAsLoaded, isImageLoaded } = useImageLoading();
  const imageUrl = media.gameMainThumbnail || media.source;
  const loaded = isImageLoaded(imageUrl);

  const handleLoad = () => {
    onLoad(); // Notify that the image has been loaded
    markImageAsLoaded(imageUrl); // Mark the image as loaded in the context
  };

  return (
    <ImageDisplayContainerWrapper>
      <ImageDisplayContainer>
        {media.type === "video" ? (
          <VideoContainer>
            <VideoElement
              src={imageUrl}
              poster={media.thumbnail}
              onLoadedData={handleLoad}
              autoPlay
              muted
              loop
              type="video/mp4"
            >
              Your browser does not support the video tag.
            </VideoElement>
          </VideoContainer>
        ) : (
          <ImageDisplay src={imageUrl} alt={media.title} onLoad={handleLoad} />
        )}
        {!loaded && <LoadingSpinner isAbsolute={true} />}
      </ImageDisplayContainer>
    </ImageDisplayContainerWrapper>
  );
};

export default MediaDisplay;
