import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinnerContainer = styled.div`
  position: ${({ $isAbsolute }) => ($isAbsolute ? "absolute" : "relative")};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: ${({ $isAbsolute, $size }) => ($isAbsolute ? "100%" : $size)};
  height: ${({ $isAbsolute, $size }) => ($isAbsolute ? "100%" : $size)};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: ${({ $isAbsolute }) =>
    $isAbsolute ? "#333" : "transparent"};
`;

const Spinner = styled.div`
  border: 3px solid rgba(0, 0, 0, 0.1);
  width: ${({ $isAbsolute, $size }) =>
    $isAbsolute
      ? "32px"
      : `calc(${$size} * 0.333)`}; /* 32px is 33.33% of the default size '24px' */
  height: ${({ $isAbsolute, $size }) =>
    $isAbsolute
      ? "32px"
      : `calc(${$size} * 0.333)`}; /* 32px is 33.33% of the default size '24px' */
  border-radius: 50%;
  border-left-color: #09f;
  animation: ${rotate} 1s ease infinite;
`;

const LoadingSpinner = ({ isAbsolute = true, size = "24px" }) => (
  <SpinnerContainer $isAbsolute={isAbsolute} $size={size}>
    <Spinner $isAbsolute={isAbsolute} $size={size} />
  </SpinnerContainer>
);

export default LoadingSpinner;
