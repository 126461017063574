import React from "react";
import styled from "styled-components";
import { media } from "../components/Breakpoints";

const SidebarWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  ${media.md`
      max-width: 260px;
    `}
`;

const SidebarComponent = ({ children }) => {
  return <SidebarWrapperContainer>{children}</SidebarWrapperContainer>;
};

export default SidebarComponent;
