import React from "react";
import styled, { css } from "styled-components";
import Button from "../components/Button";
import { media } from "../components/Breakpoints";

// Icons
import { ReactComponent as IconChat } from "../assets/svg/IconChat.svg";

// Styled components outside of the GameInfoComponent
const GameInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
`;

const GameInfoUser = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;

  ${media.sm`
align-items: unset;
  `}
`;

const GameInfoTitle = styled.div`
  display: flex;
  align-items: self-end;
`;

const GameInfoName = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: #fff;

  // Set the default font size for when it's inside an ArchiveCard
  font-size: ${({ $isArchiveCard }) => ($isArchiveCard ? "15px" : "18px")};

  // Increase font size on medium devices if it's an ArchiveCard
  ${({ $isArchiveCard }) =>
    $isArchiveCard &&
    css`
      ${media.md`
        font-size: 15px;
      `}
    `}
`;

const GameInfoAge = styled.p`
  margin: 0;
  padding: 0;
  color: #fff;
  line-height: 1.4;

  ${media.xs`
    line-height: 1.3125
  `}
`;

const GameInfoBio = styled.p`
  margin: 16px 0;
  padding: 0;
  color: #fff;
  font-size: 15px;
  line-height: 1.2;
`;

const GameInfoHandle = styled.a`
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

const GameInfoButton = styled(Button)`
  margin-top: auto;
`;

// GameInfoComponent
const GameInfoComponent = ({
  game,
  theme,
  showContent = true,
  isArchiveCard,
}) => {
  return (
    <GameInfo>
      <GameInfoUser>
        {game.name && (
          <GameInfoTitle>
            <GameInfoName $isArchiveCard={isArchiveCard} theme={theme}>
              {game.name}
            </GameInfoName>
            {showContent && game.age && <GameInfoAge>, {game.age}</GameInfoAge>}
          </GameInfoTitle>
        )}
        {showContent && game.website && (
          <GameInfoHandle
            href={game.website.link + game.website.affiliate}
            target="_blank"
            rel="noopener noreferrer"
            theme={theme}
          >
            {game.website.anchor}
          </GameInfoHandle>
        )}
        {showContent && game.bio && <GameInfoBio>{game.bio}</GameInfoBio>}
        {showContent && game.website && (
          <GameInfoButton
            size="md"
            type="secondary"
            icon={IconChat}
            theme={theme}
            text={`Chat with ${game.name.split(" ")[0]}`}
            href={game.website.link + game.website.affiliate}
          />
        )}
      </GameInfoUser>
    </GameInfo>
  );
};

export default GameInfoComponent;
