import React from "react";
import styled, { css } from "styled-components";
import { media } from "../components/Breakpoints";

const paddingStyles = {
  sm: "8px",
  md: "16px",
  lg: "32px",
  xl: "48px",
};

const Card = styled.div`
  --title-font-size: 16px;
  position: relative;
  background-color: #222;
  color: #fff;
  padding: 16px;
  border-radius: 16px;
  overflow: hidden;
  gap: 16px;
  display: flex;
  flex-direction: column;
  ${({ $flexy }) =>
    $flexy &&
    css`
      flex: 1 0 auto;
    `}
  box-shadow: ${({ $shouldShowBoxShadow }) =>
    $shouldShowBoxShadow ? "0 12px 12px -12px rgba(0, 0, 0, 0.1)" : "none"};

  ${media.md`
  --title-font-size: ${({ $padding }) => ($padding === "lg" ? "20px" : "16px")};
    padding: ${({ $padding }) => paddingStyles[$padding] || "16px"};
  `}
`;

const CardComponentTitle = styled.h3`
  font-weight: 600;
  margin: 0 0 8px;
  padding: 0;
  font-size: var(--title-font-size);
`;

const CardComponentContent = styled.div`
  height: 100%;
`;

const CardComponent = ({
  title,
  children,
  padding = "md",
  boxShadow = false,
  flexy = false,
}) => {
  return (
    <Card
      $padding={padding}
      $shouldShowBoxShadow={boxShadow}
      {...(flexy && { $flexy: flexy.toString() })}
    >
      {title && <CardComponentTitle>{title}</CardComponentTitle>}
      {children ? (
        <CardComponentContent>{children}</CardComponentContent>
      ) : null}
    </Card>
  );
};

export default CardComponent;
