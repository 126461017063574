import React, { useState } from "react";
import styled from "styled-components";
import useBreakpoint, { media } from "../components/Breakpoints";

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  TwitterIcon,
} from "react-share";
import Button from "./Button";

import { ReactComponent as IconShare } from "../assets/svg/IconShare.svg";

const ShareButton = styled(Button)`
  min-width: 140px;
`;

const ShareButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;

  ${media.sm`
  flex-direction: row;
  `}
`;

const ShareComponent = ({
  board,
  version,
  getEmoji,
  currentGameId,
  userName,
}) => {
  const { isDesktop } = useBreakpoint();
  const [buttonText, setButtonText] = useState("Share");

  const boardToEmoji = (board, version) => {
    return board
      .map(
        (cell, index) =>
          getEmoji(cell, version, true) + ((index + 1) % 3 === 0 ? "\n" : " ")
      )
      .join("");
  };

  const emojiBoardText = boardToEmoji(board, version);

  const shareData = {
    title: "StripTacToe.com",
    text: `Check out ${userName} on Level ${currentGameId} of StripTacToe:\n\n${emojiBoardText}\nTry it now:\nhttps://striptactoe.com`,
    url: window.location.href,
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share(shareData);
        // console.log("Game shared successfully");
      } catch (error) {
        // console.error("Error sharing:", error);
      }
    }
  };

  const copyToClipboard = async (e) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(shareData.text);
      setButtonText("Copied!");
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  React.useEffect(() => {
    const resetButtonText = () => setButtonText("Share");
    window.addEventListener("click", resetButtonText);
    return () => window.removeEventListener("click", resetButtonText);
  }, []);

  return (
    <>
      <p>
        Did you score with <strong>{userName}</strong>? Share with friends!
      </p>

      <ButtonContainer>
        {isDesktop && (
          <ShareButton
            size="md"
            type="secondary"
            icon={IconShare}
            iconPosition="right"
            text={buttonText}
            onClick={copyToClipboard}
          />
        )}

        {!isDesktop && navigator.share && (
          <Button
            size="md"
            type="secondary"
            icon={IconShare}
            iconPosition="right"
            text="Share with Friends"
            onClick={handleShare}
          />
        )}

        <ShareButtonContainer>
          <FacebookShareButton url={shareData.url} quote={shareData.text}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton url={shareData.url} title={shareData.text}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <EmailShareButton
            url={shareData.url}
            subject={shareData.title}
            body={shareData.text}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </ShareButtonContainer>
      </ButtonContainer>
    </>
  );
};

export default ShareComponent;
