import React from "react";
import styled, { css } from "styled-components";
import * as constants from "../constants";

// Define the base styles for all buttons
const BaseButtonStyles = css`
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  border: none;
  background-color: transparent;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
  min-width: ${({ $minWidth }) => ($minWidth ? "180px" : "auto")};
  cursor: pointer;
  text-decoration: none;
  position: relative;

  svg {
    ${({ size }) =>
      size === "lg"
        ? "width: 24px; height: 24px;"
        : size === "sm"
        ? "width: 16px; height: 16px;"
        : "width: 20px; height: 20px;"}
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

// Define the styles for different types of buttons
const PrimaryButtonStyles = css`
  background-color: ${constants.BRAND_COLORS.primaryBackground};
  color: ${constants.BRAND_COLORS.primaryColor};
  fill: ${constants.BRAND_COLORS.primaryColor};
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${constants.BRAND_COLORS.primaryBackgroundHover};
    opacity: 0.8;
  }

  &:active {
    background-color: ${constants.BRAND_COLORS.primaryBackgroundActive};
    opacity: 0.6;
  }

  &:disabled {
    background-color: ${constants.BRAND_COLORS.primaryBackgroundDisabled};
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const SecondaryButtonStyles = css`
  background-color: ${constants.BRAND_COLORS.secondaryBackground};
  color: ${constants.BRAND_COLORS.secondaryColor};
  fill: ${constants.BRAND_COLORS.secondaryColor};
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${constants.BRAND_COLORS.secondaryBackgroundHover};
    opacity: 0.8;
  }

  &:active {
    background-color: ${constants.BRAND_COLORS.secondaryBackgroundActive};
    opacity: 0.6;
  }

  &:disabled {
    background-color: ${constants.BRAND_COLORS.secondaryBackgroundDisabled};
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const OutlineButtonStyles = css`
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  border: 1px solid #fff;

  &:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
    opacity: 0.8;
  }

  &:active {
    background-color: #dae0e5;
    opacity: 0.6;
  }

  &:disabled {
    background-color: #eee;
    color: #000;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const SquishButtonStyles = css`
  position: relative;
  background-color: transparent;
  color: #7ce3cb;
  fill: #7ce3cb;
  border: none;
  cursor: pointer;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: -1;
    transition: transform 0.2s ease;
    border-radius: inherit;
  }

  &:hover {
    fill: black;
    &:after {
      background-color: lightblue;
      animation: squish 0.4s ease forwards;
    }
  }

  &:disabled {
    background-color: transparent;
    fill: #aaa;
    color: #aaa;
    cursor: not-allowed;

    &:hover:after {
      animation: none;
      background-color: transparent;
    }
  }

  @keyframes squish {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }
`;

const ButtonStyles = styled.button`
  ${({ size }) =>
    size === "lg"
      ? "padding: 12px 20px; font-size: 1rem;"
      : size === "sm"
      ? "padding: 6px 10px; font-size: 0.8rem;"
      : "padding: 8px 16px; font-size: 0.9rem;"}
  ${BaseButtonStyles}

  ${({ type }) => {
    switch (type) {
      case "primary":
        return PrimaryButtonStyles;
      case "secondary":
        return SecondaryButtonStyles;
      case "outline":
        return OutlineButtonStyles;
      case "squish":
        return SquishButtonStyles;
      default:
        return null;
    }
  }}
`;

const Button = React.forwardRef(
  (
    {
      text,
      children,
      size = "md",
      type,
      isActive,
      disabled,
      $fullWidth,
      $minWidth,
      icon: Icon,
      iconPosition = "left",
      ariaLabel,
      href,
      ...props
    },
    ref
  ) => {
    // If href is provided, use an 'a' element instead of 'button'
    const Element = href ? "a" : "button";

    const onlyIcon = Icon && !text;
    return (
      <ButtonStyles
        as={Element}
        ref={ref} // Forward ref to the styled component
        size={size}
        type={type}
        disabled={disabled}
        $fullWidth={$fullWidth}
        $minWidth={$minWidth}
        aria-label={ariaLabel || (onlyIcon ? text : undefined)}
        href={href}
        {...(href && { target: "_blank", rel: "noopener noreferrer" })}
        {...props}
      >
        {Icon && <Icon />}
        {text && <span>{text}</span>}
      </ButtonStyles>
    );
  }
);

export default Button;
