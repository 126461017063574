import React, { useMemo } from "react";
import styled, { keyframes } from "styled-components";
import Button from "../components/Button";
import useBreakpoint, { media } from "../components/Breakpoints";
import { allMessageSets } from "../messages";

// Icons
import { ReactComponent as IconSendMessage } from "../assets/svg/IconSendMessage.svg";
import { ReactComponent as IconCircleXMark } from "../assets/svg/IconCircleXMark.svg";

const PADDING_MOBILE = "16px";
const PADDING = "24px";

const ChatSpecialContainer = styled.a`
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const ChatSpecialHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 8px ${PADDING_MOBILE};
  background: #f7f7f7;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  gap: 16px;
  position: relative;

  ${media.sm`
  padding: 16px 24px;
  `}
`;

const ChatSpecialHeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  width: 100%;
`;

const ChatSpecialProfileImageContainer = styled.div`
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
`;

const ChatSpecialProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ChatSpecialProfileName = styled.span`
  font-size: 18px;
  color: #333;

  ${media.sm`
  font-size: 20px;
  `}
`;

const ChatSpecialContent = styled.div`
  padding: ${PADDING_MOBILE} 0;
  position: relative;
  height: 100%;

  ${media.sm`
  padding: 16px 0 24px;
  `}
`;

const ChatSpecialCloseButton = styled(Button)`
  z-index: 1;
  position: relative;
`;

const ChatSpecialContentList = styled.ul`
  list-style: none;
  ${({ theme }) => `
  padding: 0 ${PADDING_MOBILE} ${
    theme === "pending" || theme === "incomplete" ? "54px" : "54px"
  };
`}
  margin: 0;
  gap: 12px;
  flex-direction: column;
  display: flex;
  max-height: 180px;

  ${media.sm`
  ${({ theme }) => `
  padding: 0 ${PADDING} ${
    theme === "pending" || theme === "incomplete" ? "84px" : "84px"
  };
  `}

  `}
`;

const ChatSpecialContentItemWrapper = styled.li`
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: ${(props) => (props.$isSender ? "flex-start" : "flex-end")};
`;

const ChatSpecialContentItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.$isSender
      ? "#D98891"
      : "#2B40FF"}; // Greenish for sender, white for receiver
  border-radius: ${(props) =>
    props.$isSender ? "0 8px 8px 8px" : "8px 0 8px 8px"};
  padding: 6px 8px;
  max-width: 236px;
  min-height: 12px;
  justify-content: center;

  ${media.sm`
  padding: 10px;
  `}
`;

const ChatSpecialContentText = styled.p`
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;

  ${media.sm`
  font-size: 15px;
  `}
`;

const ChatSpecialContentTimestamp = styled.span`
  font-size: 12px;
  color: #999;
  padding: 0 10px;
  text-align: ${(props) => (props.$isSender ? "left" : "right")};
`;

const ChatSpecialContentImage = styled.img`
  width: 120px;
  height: 168px;
  border-radius: 8px;
  margin-bottom: 4px;
  object-fit: cover;
`;

const ChatSpecialInputContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;

  ${media.sm`
  padding: 24px;
  `}

  &:before {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    background: rgba(247, 247, 247, 0.8);
    backdrop-filter: blur(8px);
    border-radius: 0 0 8px 8px;
  }
`;

const ChatSpecialInputFormControl = styled.p`
  flex: 1;
  padding: 4px 12px;
  margin: 0;
  font-size: 14px;
  border: 2px solid #fff;
  border-radius: 8px;
  background-color: #fff;
  color: #777;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${media.sm`
  padding: 16px;
  font-size: 16px;
  `}

  &:focus,
  &:hover {
    border: 2px solid #d98891;
  }
`;

const ChatSpecialInputButton = styled(Button)`
  padding: 8px 12px;
  background-color: #d98891;
  border-radius: 8px;

  ${media.sm`
    padding: 16px;
    `}

  svg {
    width: 16px;
    height: 16px;

    ${media.sm`
      width: 24px;
      height: 24px;
      `}
  }
`;

const typingAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
`;

const Dot = styled.span`
  background-color: #fff;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 1px;
  animation: ${typingAnimation} 1.5s infinite;

  ${media.sm`
    width: 8px;
    height: 8px;
    `}

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.5s;
  }
  &:nth-child(3) {
    animation-delay: 1s;
  }
`;

// Define these functions outside the component
const getCurrentTime = () => {
  return new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};

const getLocalUserTimeOffset = () => {
  const date = new Date();
  date.setMinutes(date.getMinutes() - 3);
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

const ChatApp = ({
  userImage,
  userName,
  lastImage,
  chatLink,
  onClose,
  showCloseButton = true,
  theme,
  chatId,
}) => {
  const { isDesktop } = useBreakpoint();

  const userNameShort = userName.split(" ")[0];

  const safeChatId = Number.isInteger(chatId) ? chatId : 0;
  const selectedMessagesIndex = useMemo(
    () => (safeChatId - 1) % allMessageSets.length,
    [safeChatId]
  );

  const selectedMessages = useMemo(() => {
    const messageSet = allMessageSets[selectedMessagesIndex] || [];
    const dynamicEarlierToday = getLocalUserTimeOffset();
    const dynamicJustNow = getCurrentTime();

    let messages = messageSet.map((message) => ({
      ...message,
      timestamp: message.isSender ? dynamicEarlierToday : dynamicJustNow,
    }));

    if (theme === "complete" && lastImage) {
      messages.push({
        image: lastImage,
        isSender: true,
        timestamp: dynamicJustNow,
      });
    }
    if (theme === "pending") {
      messages.push({
        dots: true,
        isSender: true,
      });
    }

    return messages;
  }, [selectedMessagesIndex, theme, lastImage]);

  const handleCloseClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClose();
  };

  return (
    <ChatSpecialContainer
      href={chatLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ChatSpecialHeader>
        <ChatSpecialHeaderContent>
          <ChatSpecialProfileImageContainer>
            <ChatSpecialProfileImage src={userImage} alt="" />
          </ChatSpecialProfileImageContainer>
          <ChatSpecialProfileName>{userNameShort}</ChatSpecialProfileName>
        </ChatSpecialHeaderContent>
        {showCloseButton && (
          <ChatSpecialCloseButton
            type="primary"
            size="sm"
            ariaLabel="Close"
            icon={IconCircleXMark}
            onClick={handleCloseClick}
          />
        )}
      </ChatSpecialHeader>
      <ChatSpecialContent>
        <ChatSpecialContentList theme={theme}>
          {selectedMessages &&
            selectedMessages.map((message, index) => (
              <ChatSpecialContentItemWrapper
                key={message.id ? `${message.id}-${index}` : index}
                $isSender={message.isSender}
                $isDesktop={isDesktop}
              >
                <ChatSpecialContentItem $isSender={message.isSender}>
                  {message.text && (
                    <ChatSpecialContentText>
                      {message.text}
                    </ChatSpecialContentText>
                  )}
                  {message.image && (
                    <ChatSpecialContentImage src={message.image} />
                  )}
                  {message.dots && (
                    <DotsContainer>
                      <Dot />
                      <Dot />
                      <Dot />
                    </DotsContainer>
                  )}
                </ChatSpecialContentItem>
                {!message.dots && isDesktop && (
                  <ChatSpecialContentTimestamp>
                    {message.timestamp}
                  </ChatSpecialContentTimestamp>
                )}
              </ChatSpecialContentItemWrapper>
            ))}
        </ChatSpecialContentList>
        <ChatSpecialInputContainer>
          <ChatSpecialInputFormControl>
            Type a message to {userNameShort}
          </ChatSpecialInputFormControl>
          <ChatSpecialInputButton
            size="lg"
            type="secondary"
            icon={IconSendMessage}
            ariaLabel={`Chat with ${userNameShort}`}
          />
        </ChatSpecialInputContainer>
      </ChatSpecialContent>
    </ChatSpecialContainer>
  );
};

export default React.memo(ChatApp);
